import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {GET_PRODUCTS} from "../../graphql/Queries/Product";
import {IProduct} from "../../types/Types";
import TableToolKit from "../../components/TableNext/TableToolKit";
import {ItemsListRow} from "../../types/table/TableTypes";
import {listItemsColumns} from "../../constants/TableColumns";
import NumberFormat from 'react-number-format';
import {smallCentsWithPrefix} from "../../util/uiComponents";
import DeleteProduct from "./CartRow/DeleteProduct";
import BtnUpdateProduct from "./CartRow/BtnUpdateProduct";

const ListProducts: React.FC = () => {
    const { loading, error, data } = useQuery(GET_PRODUCTS, {
        onError: error1 => {
            console.log('problem subcategories')
        }
    });

    if (loading) return <div>
        <p>Loading...</p>
    </div>;

    if (error) return <p> Error..</p>;

    const listRows = (products: IProduct[]) => {
      return products.map((product: IProduct, index: number) => {

          const itemRow: ItemsListRow = {
              key: index + 1,
              title: product.title,
              product_code: product.product_code,
              category: product.category && product.category.main_category ?
                product.category.main_category.mainCategory: "-",
              subCat: product.category && product.category.sub_category ?
                product.category.sub_category.subCategory : "-",
              status: 'Available',
              price: <NumberFormat value={product.price.amount} thousandSeparator={true} displayType='text'
                                   prefix='Rs. '
                                   decimalScale={2} fixedDecimalScale={true}
                                   renderText={smallCentsWithPrefix}/>,
              removeIcon: <DeleteProduct product={product}/>,
              editIcon: <BtnUpdateProduct product={product}/>
          };
          return itemRow
      })
    };

    const getTable = () => {
        // console.log(data.products);
        const rows = listRows(data.products);

        return <TableToolKit columns={listItemsColumns}
                             tableData={rows} keyField={'key'}
                             search={''}
                             tableClasses='tours-table'
                             emptyImg={''}
                             noDataTitle={'Your Inventory is empty'}
                             noDataSubTitle={'Add products to your inventory :)'}
        />;
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={12} xs={12}>
                    <Card className="card-user">
                        <Card.Header>
                            <Card.Title>Products</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {getTable()}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default ListProducts;