import React from "react";

const TermsAndConditionsView: React.FC = () => {
  const siteManagement = [
    {
      key: "1",
      description:
        "monitor the Site for violations of these Terms and Conditions;",
    },
    {
      key: "2",
      description:
        "take appropriate legal action against anyone who, in our sole discretion, violates the law\n" +
        "or these Terms and Conditions, including without limitation, reporting such user to law\n" +
        "enforcement authorities;",
    },
    {
      key: "3",
      description:
        "in our sole discretion and without limitation, refuse, restrict access to, limit the\n" +
        "availability of, or disable (to the extent technologically feasible) any of your Contributions\n" +
        "or any portion thereof;",
    },
    {
      key: "4",
      description:
        "or otherwise disable all files and content that are excessive in size or are in any way\n" +
        "burdensome to our systems;",
    },
    {
      key: "5",
      description:
        "otherwise manage the Site in a manner designed to protect our rights and property and\n" +
        "to facilitate the proper functioning of the Site.",
    },
  ];
  const userContributions = [
    {
      key: "1",
      description:
        "the creation, distribution, transmission, public display, or performance, and the\n" +
        "accessing, downloading, or copying of your Contributions do not and will not infringe\n" +
        "the proprietary rights, including but not limited to the copyright, patent, trademark,\n" +
        "trade secret, or moral rights of any third party.",
    },
    {
      key: "2",
      description:
        "you are the creator and owner of or have the necessary licenses, rights, consents,\n" +
        "releases, and permissions to use and to authorize us, the Site, and other users of theSite to use your Contributions in any manner contemplated by the Site and these\n" +
        "Terms and Conditions.",
    },
    {
      key: "3",
      description:
        "you have the written consent, release, and/or permission of each and every\n" +
        "identifiable individual person in your Contributions to use the name or likeness of\n" +
        "each and every such identifiable individual person to enable inclusion and use of your\n" +
        "Contributions in any manner contemplated by the Site and these Terms and\n" +
        "Conditions.",
    },
    {
      key: "4",
      description:
        "your Contributions are not false, inaccurate, or misleading.",
    },
    {
      key: "5",
      description:
        "your Contributions are not unsolicited or unauthorized advertising, promotional\n" +
        "materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of\n" +
        "solicitation.",
    },
    {
      key: "6",
      description:
        "your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing,\n" +
        "libelous, slanderous, or otherwise objectionable (as determined by us).",
    },
    {
      key: "7",
      description:
        "your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.",
    },
    {
      key: "8",
      description:
        "your Contributions do not advocate the violent overthrow of any government or incite,\n" +
        "encourage, or threaten physical harm against another.",
    },
    {
      key: "9",
      description:
        "your Contributions do not violate any applicable law, regulation, or rule.",
    },
    {
      key: "10",
      description:
        "your Contributions do not violate the privacy or publicity rights of any third party..",
    },
    {
      key: "11",
      description:
        "your Contributions do not contain any material that solicits personal information from\n" +
        "anyone under the age of 18 or exploits people under the age of 18 in a sexual or\n" +
        "violent manner.",
    },
    {
      key: "12",
      description:
        "your Contributions do not violate any country, federal or state law concerning child\n" +
        "pornography, or otherwise intended to protect the health or well-being of minors;",
    },
    {
      key: "13",
      description:
        "your Contributions do not include any offensive comments that are connected to race,\n" +
        "national origin, gender, sexual preference, or physical handicap.",
    },
    {
      key: "14",
      description:
        "your Contributions do not otherwise violate, or link to material that violates, any\n" +
        "provision of these Terms and Conditions, or any applicable law or regulation.",
    },
  ];

  const generalTerms = [
    {
      key: "1",
      description:
        "Vendor Site will be similar to Sample prototype provide in store.spencehub.com. Only\n" +
        "the color theme will be changed by Us. Any additional changes will incur additional costs.\n" +
        "You must provide all the required resources and content data in digital format within one\n" +
        "month from the project start date.",
    },
    {
      key: "2",
      description:
        "We will start the project within three months from receiving the complete advance\n" +
        "payment.",
    },
    {
      key: "3",
      description:
        "you must purchase the domain name for the website and other required resources\n" +
        "applicable to you before start of the development.",
    },
    {
      key: "4",
      description:
        "you must pay the monthly charges to SoftVessel (Pvt) Ltd., within the first week of the\n" +
        "month. If you are using the Annual payment plan, full payment of the annual payment must\n" +
        "be paid within the first week of the first month of the 12 month period.",
    },
    {
      key: "5",
      description:
        "We have the right to change monthly charges at any time and updated charges will be\n" +
        "displayed in store.spencehub.com/pricing page.",
    },
    {
      key: "6",
      description:
        "Site or Vendor Site will not be transferred to your hosting accounts at any point.",
    },
    {
      key: "7",
      description:
        "source code of the Site or Vendor Site will not given to you at any point.\n" +
        "We own the right, to use or publish app/website name, logos, screenshots or any other\n" +
        "material related to the project on any type of media, and display SoftVessel and\n" +
        "spencehub logo and names on the website as the developer.",
    },
    {
      key: "8",
      description:
        "you must make the final payment within one week of the project completion.",
    },
    {
      key: "9",
      description:
        "in case if you failed to settle final payment within two weeks, we has the authority to\n" +
        "make a decision whether to continue the project or not. If decision is not to continue,\n" +
        "advance payment will not be refunded and no part of source code or development material\n" +
        "will be given to you.",
    },
    {
      key: "10",
      description:
        "you can inquire the progress and quality level periodically during the initial setup.",
    },
    {
      key: "11",
      description:
        "we have the authority to manage development process and steps. We are\n" +
        "not bound to deploy to testing or production until the delivery date of the project.",
    },
    {
      key: "12",
      description:
        "payments and delivery timeline will be agreed separately for the new requirements.\n" +
        "Design changes, functionality changes, or any other changes made by you will be charged\n" +
        "separately.",
    },
    {
      key: "13",
      description:
        "we will start theme customization and initial setup after receive 50% advance payment\n" +
        "of the total annual price.",
    },
    {
      key: "14",
      description:
        "After placing an order, you will receive online notification from us acknowledging that\n" +
        "we have received your order. Please note that this does not mean that your order has\n" +
        "been accepted. Your order constitutes an offer to us to buy a Product or Service. All orders\n" +
        "are subject to acceptance by us. The contract between us (Contract) will only be formed\n" +
        "when you receive the Products and/or Services (as applicable).",
    },
    {
      key: "15",
      description:
        " discount coupons are applicable only for the first twelve months of the package fees.\n" +
        "Coupons are valid for both annual and monthly pricing plans.",
    },
  ];

  const userRepresentations = [
    {
      key: "1",
      description:
        "all registration information you submit will be true, accurate, current, and complete.",
    },
    {
      key: "2",
      description:
        "you will maintain the accuracy of such information and promptly update such registration\n" +
        "information as necessary.",
    },
    {
      key: "3",
      description:
        "you have the legal capacity and you agree to comply with these Terms and Conditions.",
    },
    {
      key: "4",
      description: "you are not under the age of 13.",
    },
    {
      key: "5",
      description:
        "you will not access the Site through automated or non-human means, whether through\n" +
        "a bot, script, or otherwise;",
    },
    {
      key: "6",
      description:
        "you will not use the Site for any illegal or unauthorized purpose;",
    },
    {
      key: "7",
      description:
        "your use of the Site will not violate any applicable law or regulation.",
    },
  ];

  const prohibitedActivities = [
    {
      key: "1",
      description:
        "systematically retrieve data or other content from the Site to create or compile,\n" +
        "directly or indirectly, a collection, compilation, database, or directory without written\n" +
        "permission from us.",
    },
    {
      key: "2",
      description:
        "make any unauthorized use of the Site, including collecting usernames and/or email\n" +
        "addresses of users by electronic or other means for the purpose of sendingunsolicited email, or creating user accounts by automated means or under false\n" +
        "pretenses.",
    },
    {
      key: "3",
      description:
        "use a buying agent or purchasing agent to make purchases on the Site.",
    },
    {
      key: "4",
      description:
        "use the Site to advertise or offer to sell goods and services.",
    },
    {
      key: "5",
      description:
        "circumvent, disable, or otherwise interfere with security-related features of the Site,\n" +
        "including features that prevent or restrict the use or copying of any Content or\n" +
        "enforce limitations on the use of the Site and/or the Content contained therein.",
    },
    {
      key: "6",
      description: "engage in unauthorized framing of or linking to the Site.",
    },
    {
      key: "7",
      description:
        "trick, defraud, or mislead us and other users, especially in any attempt to learn\n" +
        "sensitive account information such as user passwords;",
    },
    {
      key: "8",
      description:
        "make improper use of our support services or submit false reports of abuse or\n" +
        "misconduct.",
    },
    {
      key: "9",
      description:
        "engage in any automated use of the system, such as using scripts to send comments\n" +
        "or messages, or using any data mining, robots, or similar data gathering and\n" +
        "extraction tools.",
    },
    {
      key: "10",
      description:
        "interfere with, disrupt, or create an undue burden on the Site or the networks or\n" +
        "services connected to the Site.",
    },
    {
      key: "11",
      description:
        "attempt to impersonate another user or person or use the username of another user.",
    },
    {
      key: "12",
      description: "sell or otherwise transfer your profile.",
    },
    {
      key: "13",
      description:
        "use any information obtained from the Site in order to harass, abuse, or harm\n" +
        "another person.",
    },
    {
      key: "14",
      description:
        "use the Site as part of any effort to compete with us or otherwise use the Site and/or\n" +
        "the Content for any revenue-generating endeavor or commercial enterprise.",
    },
    {
      key: "15",
      description:
        "decipher, decompile, disassemble, or reverse engineer any of the software\n" +
        "comprising or in any way making up a part of the Site.",
    },
    {
      key: "16",
      description:
        "attempt to bypass any measures of the Site designed to prevent or restrict access to\n" +
        "the Site, or any portion of the Site.",
    },
    {
      key: "17",
      description:
        "harass, annoy, intimidate, or threaten any of our employees or agents engaged in\n" +
        "providing any portion of the Site to you.",
    },
    {
      key: "18",
      description:
        "delete the copyright or other proprietary rights notice from any Content.",
    },
    {
      key: "19",
      description:
        "copy or adapt the Site’s software, including but not limited to Javascript, HTML, or\n" +
        "other code.",
    },
    {
      key: "20",
      description:
        "upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or\n" +
        "other material, including excessive use of capital letters and spamming (continuous\n" +
        "posting of repetitive text), that interferes with any party’s uninterrupted use andenjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use,\n" +
        "features, functions, operation, or maintenance of the Site.",
    },
    {
      key: "21",
      description:
        "upload or transmit (or attempt to upload or to transmit) any material that acts as a\n" +
        "passive or active information collection or transmission mechanism, including without\n" +
        "limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies,\n" +
        "or other similar devices (sometimes referred to as “spyware” or “passive collection\n" +
        "mechanisms” or “pcms”).",
    },
    {
      key: "22",
      description:
        "except as may be the result of standard search engine or Internet browser usage,\n" +
        "use, launch, develop, or distribute any automated system, including without limitation,\n" +
        "any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or\n" +
        "using or launching any unauthorized script or other software.",
    },
    {
      key: "23",
      description:
        "disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.",
    },
    {
      key: "24",
      description:
        "use the Site in a manner inconsistent with any applicable laws or regulations.",
    },
  ];

  return (
    <div className="terms-and-condition-view-wrapper overflow-auto terms-and-conditions-para-view">
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        These Terms and Conditions constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity (“you”,
        “your”) and SoftVessel Pvt Ltd (“we,” “us” or “our”), concerning your
        access to and use of the spencehub.com website as well as any other
        media form, media channel, mobile website or mobile application related,
        linked, or otherwise connected thereto (collectively, the “Site”).
        <br /> <br />
        You agree that by accessing the Site, you have read, understood, and
        agree to be bound by all of these Terms and Conditions. If you do not
        agree with all of these Terms and Conditions, then you are expressly
        prohibited from using the Site and you must discontinue use immediately.
        <br /> <br />
        This page (together with the documents referred to on it) explains the
        terms and conditions on which we supply any of the goods or products
        (Products) and services (Services) listed on our online store to you.
        Please read these terms and conditions carefully and make sure that you
        understand them, before ordering any Products or Services. You should
        download and print a copy of these terms and conditions for future
        reference.
      </p>
      <p
        className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold"
        id="terms-view-headings"
      >
        INFORMATION ABOUT SPENCEHUB
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        Spencehub is a software product developed and maintained by SoftVessel
        Pvt Ltd. duly registered business under company act in Sri Lanka in
        address 60 Cotta Road, Borella, Colombo 8. 00800. our website
        (softvessel.com)
        <br />
        <br />
        Spencehub software (spencehub.com) is an ecommerce platform where you
        can enter your products and services in an admin dashboard. These
        products and services will be listed in a website, under your own
        business name, as well as any other media form, media channel, mobile
        website or mobile application related, linked, or otherwise connected
        thereto (collectively, the “Vendor Site”).
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        GENERAL TERMS OF SERVICE
      </p>
      <ol className="float-left">
        {generalTerms.map((term, index) => (
          <li key={index}>{term.description}</li>
        ))}
      </ol>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        Supplemental terms and conditions or documents that may be posted on the
        Site from time to time are hereby expressly incorporated herein by
        reference. We reserve the right, in our sole discretion, to make changes
        or modifications to these Terms and Conditions at any time and for any
        reason.
        <br />
        <br />
        We will alert you about any changes by updating the “Last updated” date
        of these Terms and Conditions, and you waive any right to receive
        specific notice of each such change.
        <br />
        <br />
        It is your responsibility to periodically review these Terms and
        Conditions to stay informed of updates. You will be subject to, and will
        be deemed to have been made aware of and to have accepted, the changes
        in any revised Terms and Conditions by your continued use of the Site
        after the date such revised Terms and Conditions are posted.
        <br />
        <br />
        Accordingly, those persons who choose to access the Site from other
        locations do so on their own initiative and are solely responsible for
        compliance with local laws, if and to the extent local laws are
        applicable.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        INTELLECTUAL PROPERTY RIGHTS
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        Unless otherwise indicated, the Site is our proprietary property and all
        source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained
        therein (the “Marks”) are owned or controlled by us or licensed to us,
        and are protected by copyright and trademark laws and various other
        intellectual property rights and unfair competition laws of the United
        States, foreign jurisdictions, and international conventions.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        USER REPRESENTATIONS
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        By using the Site, you represent and warrant that:
      </p>
      <ol className="float-left">
        {userRepresentations.map((term, index) => (
          <li key={index}>{term.description}</li>
        ))}
      </ol>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        USER REGISTRATION
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        You may be required to register with the Site. You agree to keep your
        password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        PROHIBITED ACTIVITIES
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those that are specifically
        endorsed or approved by us.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        As a user of the Site, you agree not to:
      </p>
      <ol className="float-left">
        {prohibitedActivities.map((term, index) => (
          <li key={index}>{term.description}</li>
        ))}
      </ol>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        USER GENERATED CONTRIBUTIONS
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        The Site may invite you to chat, contribute to, or participate in blogs,
        message boards, online forums, and other functionality, and may provide
        you with the opportunity to create, submit, post, display, transmit,
        perform, publish, distribute, or broadcast content and materials to us
        or on the Site, including but not limited to text, writings, video,
        audio, photographs, graphics, comments, suggestions, or personal
        information or other material (collectively, "Contributions").
        <br />
        <br />
        Contributions may be viewable by other users of the Site and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non- proprietary. When you create or
        make available any Contributions, you thereby represent and warrant
        that:
      </p>
      <ol className="float-left">
        {userContributions.map((term, index) => (
          <li key={index}>{term.description}</li>
        ))}
      </ol>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        Any use of the Site in violation of the foregoing violates these Terms
        and Conditions and may result in, among other things, termination or
        suspension of your rights to use the Site.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        CONTRIBUTION LICENSE
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        By posting your Contributions to any part of the Site [or making
        Contributions accessible to the Site by linking your account from the
        Site to any of your social networking accounts], you automatically
        grant, and you represent and warrant that you have the right to grant,
        to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty- free, fully-paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), and
        distribute such Contributions (including, without limitation, your image
        and voice) for any purpose, commercial, advertising, or otherwise, and
        to prepare derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing. The
        use and distribution may occur in any media formats and through any
        media channels.
        <br />
        <br />
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
        <br />
        <br />
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Site. You are solely
        responsible for your Contributions to the Site and you expressly agree
        to exonerate us from any and all responsibility and to refrain from any
        legal action against us regarding your Contributions.
        <br />
        <br />
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the Site;
        and (3) to pre-screen or delete any Contributions at any time and for
        any reason, without notice. We have no obligation to monitor your
        Contributions.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        SITE MANAGEMENT
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        We reserve the right, but not the obligation, to:
      </p>
      <ol className="float-left">
        {siteManagement.map((term, index) => (
          <li key={index}>{term.description}</li>
        ))}
      </ol>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        COPYRIGHT INFRINGEMENTS
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the Site infringes upon any
        copyright you own or control, please immediately notify us using the
        contact information provided below (a “Notification”). A copy of your
        Notification will be sent to the person who posted or stored the
        material addressed in the Notification.
        <br />
        <br />
        Please be advised that pursuant to law you may be held liable for
        damages if you make material misrepresentations in a Notification. Thus,
        if you are not sure that material located on or linked to by the Site
        infringes your copyright, you should consider first contacting an
        attorney.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        TERM AND TERMINATION
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        These Terms and Conditions shall remain in full force and effect while
        you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
        AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
        WITHOUT NOTICEOR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
        (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON
        OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
        REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND
        CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
        USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY
        CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN
        OUR SOLE DISCRETION.
        <br />
        <br />
        we terminate or suspend your account for any reason, you are prohibited
        from registering and creating a new account under your name, a fake or
        borrowed name, or the name of any third party, even if you may be acting
        on behalf of the third party. In addition to terminating or suspending
        your account, we reserve the right to take appropriate legal action,
        including without limitation pursuing civil, criminal, and injunctive
        redress.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        MODIFICATIONS AND INTERRUPTIONS
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        We reserve the right to change, modify, or remove the contents of the
        Site at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Site. We also reserve the right to modify or discontinue all or part of
        the Site without notice at any time. We will not be liable to you or any
        third party for any modification, price change, suspension, or
        discontinuance of the Site.
        <br />
        <br />
        We cannot guarantee the Site or Vendor Site will be available at all
        times. We may experience hardware, software, or other problems or need
        to perform maintenance related to the Site, resulting in interruptions,
        delays, or errors.
        <br />
        <br />
        We reserve the right to change, revise, update, suspend, discontinue, or
        otherwise modify the Site at any time or for any reason without notice
        to you. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the
        Site during any downtime or discontinuance of the Site.
        <br />
        <br />
        Nothing in these Terms and Conditions will be construed to obligate us
        to maintain and support the Site or to supply any corrections, updates,
        or releases in connection therewith.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        CORRECTIONS
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        There may be information on the Site that contains typographical errors,
        inaccuracies, or omissions that may relate to the Site, including
        descriptions, pricing, availability, and various other information. We
        reserve the right to correct any errors, inaccuracies, or omissions and
        to change or update the information on the Site at any time, without
        prior notice.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        DISCLAIMER
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
        YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
        FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING,
        WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE MAKE NO WARRANTIES
        OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S
        CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL
        ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
        STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
        FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
        MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
        ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OROFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
        HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
        ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        <br />
        <br />
        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
        ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        LIMITATIONS OF LIABILITY
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        GOVERNING LAW
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        These Terms and Conditions and your use of the Site and Vendor Site are
        governed by and construed in accordance with the laws of Sri Lanka
        applicable to agreements made and to be entirely performed within the
        State/Commonwealth of Western Province, without regard to its conflict
        of law principles.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        INDEMNIFICATION
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) [your
        Contributions]; (2) use of the Site; (3) breach of these Terms and
        Conditions; (4) any breach of your representations and warranties set
        forth in these Terms and Conditions; (5) your violation of the rights of
        a third party, including but not limited to intellectual property
        rights; or (6)any overt harmful act toward any other user of the Site
        with whom you connected via the Site.
        <br />
        <br />
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        USER DATA
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        We will maintain certain data that you transmit to the Site for the
        purpose of managing the Site, as well as data relating to your use of
        the Site. Although we perform regular routine backups of data, you are
        solely responsible for all data that you transmit or that relates to any
        activity you have undertaken using the Site.
        <br />
        <br />
        You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify font-weight-bold">
        CONTACT US
      </p>
      <p className="pl-0 pr-2 terms-and-conditions-para-view-para text-justify">
        In order to resolve a complaint regarding the Site or to receive further
        information regarding use of the Site, please contact us at:
        <br />
        <br />
        60 Cotta Road, Colombo 8, 00800 <br />
        0112680360
        <br />
        sales@softvessel.com
        <br />
      </p>
    </div>
  );
};
export default TermsAndConditionsView;
