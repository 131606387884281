import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_INVOICE } from "../../../graphql/Queries/Invoice";
import {  SingleInvoiceData } from "../../../types/InvoiceTypes";
import { Row, Col, Container, Image } from "react-bootstrap";
import CurrentInvoiceCustomer from "../../../components/SingleInvoice/CurrentInvoiceCustomer";
import InvoiceItemsTable from "../../../components/SingleInvoice/InvoiceItemsTable";
import InvoiceSummary from "../../../components/SingleInvoice/InvoiceSummary";

const SingleInvoice: React.FC = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState<SingleInvoiceData | null>(null);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);

  const [getInvoice, { data, loading, error }] = useLazyQuery(GET_INVOICE, {
    variables: { invoice_id: id },
  });

  const currencyType: string = "Rs.";

  useEffect(() => {
    getInvoice();
    if (!data) return;

    setInvoice(data.invoice);
    setInvoiceDate(new Date(parseInt(data.invoice.createdAt)));
  }, [data, getInvoice]);

  if (loading) return (<div><p>Loading...</p></div>);

  if (error) return <p> Error..</p>;
  if (!invoiceDate) return <p />;
  if (!invoice) return <p />;

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <Row>
            <Col xs={12} md={12} className="text-center mt-4 single-invoice-header">
              <Image className="pr-3" src={invoice.business.logoUrl}/>
              <span>
                {invoice.business.business_name}
              </span>
            </Col>
          </Row>
          <Row className="single-invoice-addresses">
            <Col xs={12} md={12} className="text-center" >
              <span>{invoice.business.address}</span>
            </Col>
            <Col xs={12} md={12} className="text-center" >
              <span>{invoice.business.tel}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="invoice-num mb-0">
                <strong>Invoice #: {invoice?.invoice_id}</strong>
              </p>
              <p className="print-only text-right">
                {invoiceDate && (
                  <strong>
                    Date:{" "}
                    {invoiceDate.toLocaleString("en-GB", { hour12: true })}
                  </strong>
                )}
              </p>
            </Col>
          </Row>
          <CurrentInvoiceCustomer billingInfo={invoice.billingInfo} invoiceId={invoice?.invoice_id}
                                  customer={invoice?.personalInfo}/>
          <InvoiceItemsTable currencyType={currencyType} items={invoice?.items}/>
          <Row>
            <Col xs={12}>
              <hr className="items-hr table-bottom-hr" />
            </Col>
          </Row>
          <InvoiceSummary currencyType={currencyType} items={invoice?.items} shipping={invoice?.shipping}
            total={invoice?.total}/>
        </Col>
      </Row>
    </Container>
  );
};
export default SingleInvoice;
