import React, {useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Col, Image, Row, Toast} from "react-bootstrap";
import Thumb, {PhotoFileType} from "./Thumb";
import {UPLOAD_STATUS} from "../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {removePhotoUrls, setUploadPhotoUrlStatus} from "../../store/actions/ProductActions";
import {resizeFile} from "../../util/common";
import {ImageResizeOptions} from "../../types/ImageResize";

type ImagesUploadProps = {
  uploadedPhotos?: string[]
  isNewProduct?: boolean
}

const ImagesUpload: React.FC<ImagesUploadProps> = (props) => {
  const [files, setFiles] = useState([] as any);
  const [photoFiles, setPhotoFiles] = useState<PhotoFileType[]>([]);
  const productPhotoUrls: string[] = useSelector((state: AppState) => state.product.productPhotoUrls);

  const urlsRef = useRef<string[]>();

  const {uploadedPhotos, isNewProduct} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!uploadedPhotos || uploadedPhotos.length === 0) {
      setPhotoFiles([]);
      return;
    }

    const uploadedPhotoFiles: PhotoFileType[] = uploadedPhotos.map((photoUrl: string) => {
      const uploadedPhotoFile: PhotoFileType = {preview: photoUrl, file: undefined, status: UPLOAD_STATUS.UPLOADED,
        name: undefined};
      return uploadedPhotoFile;
    });

    setPhotoFiles(uploadedPhotoFiles);

  }, [uploadedPhotos]);

  useEffect(() => {
    if (isNewProduct) {
      setPhotoFiles([]);
      dispatch(removePhotoUrls());
    }
  }, [isNewProduct]);

  useEffect(() => {
    if (photoFiles.length === productPhotoUrls.length) {
      dispatch(setUploadPhotoUrlStatus(false));
    }

    // console.log(urlsRef.current);
    // console.log(productPhotoUrls);

    if (urlsRef.current) {
      const removedItems = urlsRef.current.filter(photo => !productPhotoUrls.includes(photo));

      if (removedItems.length === 0) {
        return;
      }

      const removedIndex = urlsRef.current.indexOf(removedItems[0]);
      const updatedPhotoFiles = photoFiles.slice();
      updatedPhotoFiles.splice(removedIndex, 1);
      setPhotoFiles(updatedPhotoFiles);
    }

    urlsRef.current = productPhotoUrls;
  }, [productPhotoUrls]);

  const setUploadingStatus = () => {
    dispatch(setUploadPhotoUrlStatus(true))
  };

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      // if (acceptedFiles.length > 5) {
      //   return <div>
      //     <Toast>
      //       <Toast.Header>
      //         <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
      //         <strong className="mr-auto">Upload Error</strong>
      //       </Toast.Header>
      //       <Toast.Body>Max File limit</Toast.Body>
      //     </Toast>
      //   </div>;
      // }
      const acceptedPhotoFiles: PhotoFileType[] = acceptedFiles.map((file, index) => {


        const photoFile: PhotoFileType = {
          preview: undefined, name: file.name,
          status: UPLOAD_STATUS.UPLOADING, file: file
        };
        return photoFile;
      });
      setUploadingStatus();
      setPhotoFiles(photoFiles.concat(acceptedPhotoFiles));
      // console.log(acceptedPhotoFiles);

      // const previewFiles = acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // }));
      // setFiles(files.concat(previewFiles));
      // // console.log(acceptedFiles);
      //
      // const newFiles = acceptedFiles.map(async file => {
      //   const s3Url: string = await uploadImage(file);
      //   // console.log(s3Url);
      //
      //   const allImages = imageUrls.slice();
      //   allImages.push(s3Url.split('?')[0]);
      //   setImageUrls(allImages);
      //
      //   props.onImageUpload(allImages);
      //   // console.log(allImages);
      //
      //   return Object.assign(file, {
      //     preview: URL.createObjectURL(file)
      //   });
      // });
    }
  });

  // const uploadedThumbs = photoFiles.map((photoFile: PhotoFileType, index: number) => {
  //   return (
  //     <div key={index}>
  //       <Thumb photoFile={photoFile} index={index}/>
  //     </div>
  //   )
  // });

  const getUploadedThumbs = () => {
    {/*<div key={photoFile.preview ? photoFile.preview + index : index}>*/}

    return photoFiles.map((photoFile: PhotoFileType, index: number) => {
      return (
        <div key={photoFile.preview ? photoFile.preview + index : index}>
          <Thumb photoFile={photoFile} index={index}/>
        </div>
      )
    });
  };

  useEffect(() => () => {
    // console.log(files);
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop product images here, or click to select files</p>
      </div>
      <aside>
        <Row>
          <Col className='preview'>
            {files[0] && <Image src={files[0].preview}/>}
          </Col>
        </Row>
        <Row>
          <Col className='thumbsContainer justify-content-center'>
            {getUploadedThumbs()}
          </Col>
        </Row>
      </aside>
    </section>
  );
};

export default ImagesUpload;