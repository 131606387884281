import React from "react";
import { IProduct } from "../../types/Types";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { smallCentsWithPrefix } from "../../util/uiComponents";

type InvoiceItemProps = {
  product: IProduct;
  num: number;
  qty: number;
};

const InvoiceItem: React.FC<InvoiceItemProps> = (props) => {
  const { num, product, qty } = props;

  return (
    <Row className={"item-data pt-3 ml-sm-1 mr-sm-1 "}>
      <Col xs={1} className="border-right2 pl-1 pr-1">
        {num}
      </Col>
      <Col className="border-right2 pr-1 col-print-6">{product.title}</Col>
      <Col xs={2} className="border-right2 text-right col-print-2">
        <NumberFormat
          value={product.price.amount}
          thousandSeparator={true}
          displayType="text"
          prefix=""
          decimalScale={2}
          fixedDecimalScale={true}
          renderText={smallCentsWithPrefix}
        />
      </Col>
      <Col xs={2} className="border-right2 pl-1 pr-1 text-center col-print-1">
        {qty}
      </Col>
      <Col xs={3} md={2} className="text-right pl-md-0 pr-md-1">
        <NumberFormat
          value={product.price.amount * qty}
          thousandSeparator={true}
          displayType="text"
          prefix=""
          decimalScale={2}
          fixedDecimalScale={true}
          renderText={smallCentsWithPrefix}
        />
      </Col>
    </Row>
  );
};
export default InvoiceItem;
