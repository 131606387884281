import React, {useState} from 'react';
import {useMutation} from "@apollo/react-hooks";
import {DELETE_PRODUCT} from "../../../graphql/Mutations/Product";
import {IProduct} from "../../../types/Types";
import {GET_PRODUCTS} from "../../../graphql/Queries/Product";
import {Button, Modal} from "react-bootstrap";
import {BUSINESS_ID} from "../../../variables/business";

type DeleteProductProps = {
    product: IProduct
}

const DeleteProduct: React.FC<DeleteProductProps> = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [deleteProduct] = useMutation(DELETE_PRODUCT);
    const {product} = props;

    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        deleteProduct({
            variables: {id: product._id, business: BUSINESS_ID},
            optimisticResponse: true,
            update: (cache) => {
                const exitingProducts = cache.readQuery({query: GET_PRODUCTS});
                // @ts-ignore
                const newProducts = exitingProducts.products.filter(p => (p._id !== product._id));
                cache.writeQuery({
                    query: GET_PRODUCTS,
                    data: {products: newProducts}
                })
            }
        });
        handleClose()
    };

    return (
        <React.Fragment>
            <div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <div className='del-icon-info'>
                        <i className='feather icon-info'/>
                    </div>
                    <Modal.Body>
                        <div className='md-body'>
                            <p>Are you sure you want to delete this product?</p>
                        </div>
                        <div className='text-center'>
                            <Button variant="secondary" className='product-cancel-btn' onClick={handleClose}>
                                No, Cancel
                            </Button>
                            <Button variant="primary" className='product-del-btn' onClick={handleClick}>Yes, Delete
                                it</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <div className='tb-trash-icon'>
                <i className="feather icon-trash"
                   onClick={handleShow}/>
            </div>
        </React.Fragment>
    )
};

export default DeleteProduct;