import React, {useState} from 'react';
import {Button, FormControl, InputGroup} from "react-bootstrap";

const LockScreen: React.FC = () => {
    const [isLoginError, setIsLoginError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    return (
        <div className='login-dialog'>
            <h3>Unlock Screen</h3>

            <form className='login-form'>
                <InputGroup>
                    <InputGroup.Prepend>
                        <i className='nc-icon nc-lock-circle-open'/>
                    </InputGroup.Prepend>
                    <FormControl className='form-style' type='password' name='pin' placeholder="Pin number"/>
                </InputGroup>

                <div className='error-container'>
                    {isLoginError &&
                    <p className='login-error'>{message}</p>
                    }
                </div>

                <div className="login ml-auto mr-auto">
                    <Button variant="primary" className='btn-block' type='submit'>Unlock</Button>
                </div>
                <hr className='divider'/>
                <div>
                    <p className='developer'> &copy; {new Date().getFullYear()} developed by <a href="http://www.softvessel.com" target="_blank"> SoftVessel</a></p>
                </div>
            </form>
        </div>
    )
};

export default LockScreen;