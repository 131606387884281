import React from "react";
import "./App.scss";
import {BrowserRouter, Router, Route, Switch, Redirect} from "react-router-dom";
import indexRoutes from "./routes";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { createBrowserHistory } from "history";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { STORAGE_USER } from "./constants/StorageUser";
import { Provider } from "react-redux";
import configureStore from "./store";
import {API_URL} from "./constants/ServerURL";

// const client = new ApolloClient({
//     // uri: "http://localhost:4000/spencehub"
//     uri: "http://localhost:4200/graphql"
// });

const httpLink = createHttpLink({
  // uri: GRAPHQL,
  uri: API_URL + "/graphql",
  credentials: "include",
});

const authLink = setContext((_, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const storeUser = localStorage.getItem("user");
  if (storeUser) {
    const user = JSON.parse(storeUser);
    let token = null;
    if (user) {
      token = user.token;
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const onGraphQLError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("GQ Err -");
    console.log(graphQLErrors);
    console.log(graphQLErrors[0].message);

    const errorMsg = graphQLErrors[0].message;
    if (errorMsg.indexOf("unauthorized") > -1) {
      localStorage.removeItem(STORAGE_USER);
      // :TODO redirect to unauth
      // history.push('/auth/signin-1');
    }
    // sendToLoggingService(graphQLErrors);
  }
  if (networkError) {
    // alert("Network Error - Try again later.");
    console.log(networkError);
    // logoutUser();
  }
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  // link: authLink.concat(onGraphQLError).concat(httpLink)
  // link: httpLink,
  link: authLink.concat(onGraphQLError).concat(httpLink),
  connectToDevTools: true,
});

function App() {
  const history = createBrowserHistory();
  const store = configureStore();

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Router history={history}>
          <BrowserRouter>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    // exact={true}
                    component={prop.component}
                  />
                );
              })}
            </Switch>
          </BrowserRouter>
        </Router>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
