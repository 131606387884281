import React, { FormEvent, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import OptionsSubCategory from "./OptionsSubCategory";
import { useMutation } from "@apollo/react-hooks";
import { EDIT_SUB_CATEGORY } from "../../graphql/Mutations/Category";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import { setSelectedCategory } from "../../store/actions/CategoryActions";

export interface SubCategoryItemProps {
  title: string;
  subCategoryId: string;
}

const SubCategoryItem: React.FC<SubCategoryItemProps> = (props) => {
  const [editSubCategory, { data: addData }] = useMutation(EDIT_SUB_CATEGORY, {
    onCompleted: (data) => {},
    refetchQueries: ({ data: { addCategory } }) => [{ query: GET_CATEGORIES }],
  });

  const selectedCategoryRedux = useSelector(
    (state: AppState) => state.categories.selectedCategory
  );
  const dispatch = useDispatch();
  const [toggleCategoryInput, setToggleCategoryInput] = useState(true);
  const [editCategory, setCategory] = useState(props.title);

  const toggleCategoryInputs = () => {
    if (toggleCategoryInput) {
      setToggleCategoryInput(!toggleCategoryInput);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(event.target.value);
  };

  const onSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    setToggleCategoryInput(!toggleCategoryInput);
    if (selectedCategoryRedux === null) {
      return;
    }

    const typeRemovedSubCategories = selectedCategoryRedux.subCategories.map(
      (item: any) => {
        if (item.__typename) {
          delete item.__typename;
          if (item._id === props.subCategoryId) {
            item.subCategory = editCategory;
            return item;
          }
        } else {
          if (item._id === props.subCategoryId) {
            item.subCategory = editCategory;
            return item;
          }
          return item;
        }
        return item;
      }
    );

    editSubCategory({
      variables: {
        _id: selectedCategoryRedux._id,
        subCategories: typeRemovedSubCategories,
      },
    });

    dispatch(
      setSelectedCategory({
        ...selectedCategoryRedux,
        subCategories: typeRemovedSubCategories,
      })
    );
  };
  const onBlurHandler = () => {
    setToggleCategoryInput(!toggleCategoryInput);
  };

  const toggleCategoryInputFunction = () => {
    if (toggleCategoryInput) {
      return (
        <label style={{ fontSize: "20px", color: "black" }}>
          {editCategory}
        </label>
      );
    }
    return (
      <Form className="mt-3" onSubmit={onSubmitHandler}>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            style={{ borderWidth: "2.5px" }}
            type="text"
            autoFocus
            placeholder="Enter category"
            value={editCategory}
            onBlur={onBlurHandler}
            onChange={handleChange}
          />
        </Form.Group>
      </Form>
    );
  };

  return (
    <Row style={{ cursor: "pointer" }}>
      <Col md={12} xs={12}>
        <Card className="mb-2">
          <Card.Body
            style={{
              minHeight: "0px",
              padding: "3px 15px",
            }}
            className="pl-3, pr-3, pt-1,pb-1"
          >
            <Row style={{ alignItems: "center" }}>
              <Col md={10} xs={12} className=" pr-4">
                {toggleCategoryInputFunction()}
              </Col>
              <Col md={2} xs={12} className="mt-2">
                <OptionsSubCategory
                  toggleCategoryInputs={toggleCategoryInputs}
                  subCategoryId={props.subCategoryId}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SubCategoryItem;
