import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const Footer: React.FC = () => {
  return (
    <Container fluid={true} className="footer-home mt-0">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul className="nav justify-content-center footer-navlink footer-icons">
            <li className="nav-item">
              <i className='feather icon-map-pin'/>
            </li>
            <li className="nav-text">
              <p className='text-center'>SoftVessel (Pvt) Ltd. No. 60, Cotta Rd, Borella, Sri Lanka</p>
            </li>

            <li className="nav-item">
              <i className='feather icon-phone'/>
            </li>
            <li className="nav-text">
              <p>+94 779 510 260 / +94 112 680 360</p>
            </li>

            <li className="nav-item">
              <i className='feather icon-mail'/>
            </li>
            <li className="nav-text">
              <p>info@softvessel.com</p>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
};

export default Footer;