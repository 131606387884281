import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import MainCategory from "./MainCategory";
import SubCategory from "./SubCategory";
import { CategoryData } from "../../types/CategoryTypes";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

// @ts-ignore
const Category: React.FC = () => {
  const selectedCategory: CategoryData | null = useSelector(
    (state: AppState) => state.categories.selectedCategory
  );

  return (
    <div>
      <Row>
        <Col md={12} xs={12}>
          <Card className="card-user">
            <Card.Body>
              <Row>
                <Col md={4} xs={12} className="ver-divider pr-4">
                  <MainCategory />
                </Col>
                <Col md={4} xs={12} className="pl-5 pr-5 pt-3 pb-3">
                  <h3 className="pb-4">Sub Category</h3>
                  { selectedCategory && <SubCategory selectedCategory={selectedCategory} /> }
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Category;
