import React from 'react';
import CreateProduct from "./CreateProduct";
import {Card, Col, Row} from "react-bootstrap";
import { Link, Switch, Route } from 'react-router-dom';
import ListProducts from "./ListProducts";
import UpdateProduct from "./UpdateProduct";
import FindProduct from "./FindProduct";
import {ROOT_PATH} from "../../routes/dashboard";

const Products: React.FC = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} sm={6} md={6} lg={3}>
                    <Link to={ROOT_PATH + '/products'} className="page-nav">
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs={5} md={4}>
                                        <div className="icon-big text-center">
                                            <i className="nc-icon nc-box-2 text-warning"/>
                                        </div>
                                    </Col>
                                    <Col xs={7} md={8}>
                                        <div className="numbers">
                                            <p className="card-category">Products</p>
                                            <Card.Title>Items</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <hr/>
                                {/*<Stats>*/}
                                {/*    {[*/}
                                {/*        {*/}
                                {/*            i: "fas fa-align-justify",*/}
                                {/*            t: "List all"*/}
                                {/*        }*/}
                                {/*    ]}*/}
                                {/*</Stats>*/}
                            </Card.Footer>
                        </Card>
                    </Link>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3}>
                    <Link to={ROOT_PATH + '/products/create'} className="page-nav">
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs={5} md={4}>
                                        <div className="icon-big text-center">
                                            <i className="nc-icon nc-ruler-pencil text-success"/>
                                        </div>
                                    </Col>
                                    <Col xs={7} md={8}>
                                        <div className="numbers">
                                            <p className="card-category">Add Product</p>
                                            <Card.Title>to Store</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <hr/>
                                {/*<Stats>*/}
                                {/*    {[*/}
                                {/*        {*/}
                                {/*            i: "fas fa-seedling",*/}
                                {/*            t: "Create New"*/}
                                {/*        }*/}
                                {/*    ]}*/}
                                {/*</Stats>*/}
                            </Card.Footer>
                        </Card>
                    </Link>
                </Col>
                {/*<Col xs={12} sm={6} md={6} lg={3}>*/}
                {/*    <Link to={'/products/update'} className="page-nav">*/}
                {/*        <Card className="card-stats">*/}
                {/*            <Card.Body>*/}
                {/*                <Row>*/}
                {/*                    <Col xs={5} md={4}>*/}
                {/*                        <div className="icon-big text-center">*/}
                {/*                            <i className="nc-icon nc-single-copy-04 text-danger"/>*/}
                {/*                        </div>*/}
                {/*                    </Col>*/}
                {/*                    <Col xs={7} md={8}>*/}
                {/*                        <div className="numbers">*/}
                {/*                            <p className="card-category">Update Product</p>*/}
                {/*                            <Card.Title>to Store</Card.Title>*/}
                {/*                        </div>*/}
                {/*                    </Col>*/}
                {/*                </Row>*/}
                {/*            </Card.Body>*/}
                {/*            <Card.Footer>*/}
                {/*                <hr/>*/}
                {/*                /!*<Stats>*!/*/}
                {/*                /!*    {[*!/*/}
                {/*                /!*        {*!/*/}
                {/*                /!*            i: "fas fa-seedling",*!/*/}
                {/*                /!*            t: "Create New"*!/*/}
                {/*                /!*        }*!/*/}
                {/*                /!*    ]}*!/*/}
                {/*                /!*</Stats>*!/*/}
                {/*            </Card.Footer>*/}
                {/*        </Card>*/}
                {/*    </Link>*/}
                {/*</Col>*/}
                <Col xs={12} sm={6} md={6} lg={3} className='dis-none'>
                    <Link to={ROOT_PATH + '/products/find'} className="page-nav">
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs={5} md={4}>
                                        <div className="icon-big text-center">
                                            <i className="nc-icon nc-zoom-split text-danger"/>
                                        </div>
                                    </Col>
                                    <Col xs={7} md={8}>
                                        <div className="numbers">
                                            <p className="card-category">Find</p>
                                            <Card.Title>Items</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <hr/>
                                {/*<Stats>*/}
                                {/*    {[*/}
                                {/*        {*/}
                                {/*            i: "fas fa-inbox",*/}
                                {/*            t: "Search Product"*/}
                                {/*        }*/}
                                {/*    ]}*/}
                                {/*</Stats>*/}
                            </Card.Footer>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <Switch>
                <Route path={ROOT_PATH + '/products'} component={ListProducts} exact={true}/>
                <Route path={ROOT_PATH + '/products/create'} component={CreateProduct}/>
                <Route path={ROOT_PATH + '/products/update/:id/:title'} component={UpdateProduct} exact={true}/>
                <Route path={ROOT_PATH + '/products/find'} component={FindProduct}/>
            </Switch>
        </React.Fragment>
    )
};

export default Products;