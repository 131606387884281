import Dashboard from "../views/Dashboard/Dashboard";
import Invoice from "../views/Invoice/Invoice";
import Products from "../views/Products/Products";
import Category from "../views/Category/Category";

export const ROOT_PATH = '/dashboard';

const  dashRoutes = [
    {
        path: ROOT_PATH + "/products",
        name: "Products",
        icon: "nc-icon nc-box",
        component: Products
    },
    {
        path: ROOT_PATH + "/category",
        name: "Category",
        icon: "nc-icon nc-briefcase-24",
        component: Category
    },
    {
        path: ROOT_PATH + "/invoice/list",
        name: "Invoice",
        icon: "nc-icon nc-briefcase-24",
        component: Invoice
    },
    // {
    //     path: "/customer",
    //     name: "Customer",
    //     icon: "nc-icon nc-single-02",
    //     component: Customer
    // },

    // {
    //     path: "/supplier",
    //     name: "Supplier",
    //     icon: "nc-icon nc-delivery-fast",
    //     component: Supplier
    // },

    // {
    //     path: "/grn",
    //     name: "GRN / Stock",
    //     icon: "fa fa-dolly",
    //     component: GRN
    // },
    // {
    //     path: "/business",
    //     name: "Business",
    //     icon: "nc-icon nc-briefcase-24",
    //     component: Business
    //
    // },
    {
        path: ROOT_PATH + "/",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        component: Dashboard
    },

    // { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;