import {CategoryData, SubCategory} from "../../types/CategoryTypes";
import {SetAllCategories, SetSelectedCategory, SetSelectedSubCategory,} from "../../types/CategoryStoreTypes";
import {
  SET_All_CATEGORY,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
} from "../../constants/actions/categoryActions";

export function setCategories(categories: CategoryData[]): SetAllCategories {
  return {
    type: SET_All_CATEGORY,
    payload: categories,
  };
}

export function setSelectedCategory(
  category: CategoryData | null
): SetSelectedCategory {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: category,
  };
}

export const setSelectedSubCategory = (subCategory: SubCategory | null) : SetSelectedSubCategory => {
  return {
    type: SET_SELECTED_SUB_CATEGORY,
    payload: subCategory
  }
};