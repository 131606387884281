import React from "react";
import {Button} from "react-bootstrap";

type TermsAndConditionsNextProps = {
  agreed: boolean;
  loading: boolean;
  handleTermsAgree: () => void;
};

const TermsAndConditionsNext: React.FC<TermsAndConditionsNextProps> = (props
) => {

  const {agreed, handleTermsAgree, loading} = props;
  return (
    <div>
      <Button onClick={handleTermsAgree} className={`p-2 terms-and-conditions-next-button 
               ${(agreed && !loading)  ? "btn-purple" : "disabled btn-secondary"} `}>Next</Button>
    </div>
  );
};

export default TermsAndConditionsNext;
