import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import dashRoutes from "../../routes/dashboard";
import {Button, Container, Navbar, NavbarBrand, NavItem} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/NavbarToggle";

const Header: React.FC<any> = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [color, setColor] = useState('transparent');

    const toggle = () => {
        setColor(isOpen ? 'transparent' : 'dark');
        setOpen(!isOpen);
    };

    const getBrand = () => {
        let name;
        dashRoutes.map((prop: any, key: number) => {
            if (prop.collapse) {
                prop.views.map((prop: any, key: number) => {
                    if (prop.path === props.location.pathname) {
                        name = prop.name;
                    }
                    return null;
                });
            } else {
                if (prop.redirect) {
                    if (prop.path === props.location.pathname) {
                        name = prop.name;
                    }
                } else {
                    if (prop.path === props.location.pathname) {
                        name = prop.name;
                    }
                    if (prop.path === '/' + props.location.pathname.split('/')[1]) {
                        name = prop.name;
                    }
                }
            }
            return null;
        });
        return name;
    };

    const updateColor = () => {
        setColor(window.innerWidth < 993 && isOpen ? 'dark' : 'transparent');
    };

    useEffect(() => {
        window.addEventListener('resize', updateColor);
    });

    return (
      <Navbar
        fixed="top"
        color={props.location.pathname.indexOf('full-screen-maps') !== -1 ? 'dark' : color }
        expand="lg"
        className={
            props.location.pathname.indexOf('full-screen-maps') !== -1
              ? "header-nav-bar absolute-navbar-class" : " header-nav-bar absolute-navbar-class"
              + (color === 'transparent' ? ' navbar-transparent' : '') }
     >
          <Container fluid={true}>
              <div className='navbar-wrapper'>
                  <div className='navbar-toggle'>
                      <Button type='button' className='navbar-toggler-btn' onClick={() => props.openSideBar()}>
                          <span className="navbar-toggler-bar bar1"/>
                          <span className="navbar-toggler-bar bar2"/>
                          <span className="navbar-toggler-bar bar3"/>
                      </Button>
                  </div>
                  <NavbarBrand className="mt-3" href='/'>{getBrand()}</NavbarBrand>
              </div>
              <NavbarToggle className="p-2" onClick={() => toggle()}>
                  <span className="navbar-toggler-bar navbar-kebab"/>
                  <span className="navbar-toggler-bar navbar-kebab"/>
                  <span className="navbar-toggler-bar  navbar-kebab"/>
              </NavbarToggle>

              <Navbar>
                  <NavItem>
                      <Link to="/logout" className="nav-link btn-rotate mb-2 mt-0 mb-md-0 mt-md-3">
                          <i className="nc-icon nc-button-power navbar-power-button-icon pr-3"/>
                      </Link>
                  </NavItem>
              </Navbar>
          </Container>
      </Navbar>
    )
};

export default Header;