import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import TermsAndConditionsView from "../../components/TermsAndConditions/TermsAndConditionsView";
import TermsAndConditionsAgree from "../../components/TermsAndConditions/TermsAndConditionsAgree";
import TermsAndConditionsNext from "../../components/TermsAndConditions/TermsAndConditionsNext";
import * as url from "../../assets/img/spencehub.2055e931.png";
import TermsAndConditionsHeader from "../../components/TermsAndConditions/TermsAndConditionsHeader";
import { NavLink, Redirect } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { EDIT_BUSINESS_AGREEMENT } from "../../graphql/Mutations/Business";
import { getLoadingSpan } from "../../util/uiComponents";
import { STORAGE_USER } from "../../constants/StorageUser";

const TermsAndConditions: React.FC = () => {
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [redirectNow, setRedirectNow] = useState<boolean>(false);

  const [editBusinessAgreement, { loading, error }] = useMutation(
    EDIT_BUSINESS_AGREEMENT,
    {
      onCompleted: () => {
        const storeUser = localStorage.getItem("user");

        if (!storeUser) {
          return;
        }

        const user = JSON.parse(storeUser);
        const tempUser = { ...user, AG: true };
        localStorage.removeItem(STORAGE_USER);
        localStorage.setItem(STORAGE_USER, JSON.stringify(tempUser));
        setRedirectNow(true);
      },
    }
  );

  const renderRedirect = () => {
    if (!loading && !error && redirectNow) {
      return <Redirect to="/dashboard" />;
    }
  };

  const handleChange = (event: any) => {
    setIsAgreed(!isAgreed);
  };

  const handleTermsAgree = () => {
    editBusinessAgreement({
      variables: {
        AG: true,
      },
    });
  };

  const getSpinner = () => {
    if (loading) {
      return getLoadingSpan();
    }
  };

  return (
    <React.Fragment>
      {renderRedirect()}
      <div className="terms-and-conditions-top-wrapper">
        <Container className="terms-and-conditions--main-wrapper pt-md-5">
          <Card body className="m-1 mt-5 terms-and-conditions-card mt-md-4">
            <Row>
              <TermsAndConditionsHeader />
              <Col md={12} className="p-0 hr-col">
                <hr />
              </Col>
              <Col md={12} className="mt-1 ml-1 mr-1 mt-md-2 ml-md-5 mr-md-5">
                <TermsAndConditionsView />
              </Col>
              <Col
                md={12}
                className="mt-1 ml-1 mr-1 mt-md-2 ml-md-5 mr-md-5 text-center"
              >
                {getSpinner()}
              </Col>
              <Col md={12} className="mt-1 mt-md-2 mb-md-1 p-0 hr-col">
                <hr />
              </Col>
              <Col
                xs={12}
                md={12}
                className="mt-1 ml-1 mr-1 ml-md-5 mr-md-5 terms-and-condition-agree"
              >
                <TermsAndConditionsAgree
                  checked={isAgreed}
                  handleChange={handleChange}
                />
              </Col>
              <Col xs={12} className="terms-and-conditions-download-next">
                <Row>
                  <Col
                    md={7}
                    className=" mr-1 mt-1 mt-md-3 ml-md-5 mr-md-5 terms-and-conditions-download"
                  >
                    <a
                      target="_blank"
                      href="https://s3.us-east-1.amazonaws.com/cdn1.shub/terms/TermsAndConditions.pdf"
                      className="text-warning ml-md-1 terms-and-conditions-download-pdf"
                    >
                      <i className="feather icon-download pr-1" />
                      <u> Download T&C PDF</u>
                    </a>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    className="terms-and-conditions-next float-right terms-and-conditions-next-button-wrapper"
                  >
                    <TermsAndConditionsNext
                      handleTermsAgree={handleTermsAgree}
                      loading={loading}
                      agreed={isAgreed}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default TermsAndConditions;
