import React from 'react';
import SpecItemRow from "./SpecItemRow";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {SpecItem} from "../../types/SpecStoreTypes";

const SpecItemList: React.FC = () => {

  const specItems: SpecItem[] | undefined = useSelector((state: AppState) => state.spec.spec?.specItems);

  return (
    <div>
      {
        specItems && specItems.map((specItem, index) => <SpecItemRow key={index} index={index}/>)
      }
    </div>
  )
};

export default SpecItemList;