import React, {ChangeEvent, useEffect, useState} from 'react';
import CreatableSelect from "react-select/creatable";
import {Col, Form, Row} from "react-bootstrap";
import {customStylesInAddSubSpecsRow} from "../../constants/CreateProduct";
import {SampleSpec, Spec} from "../../types/SpecStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {SelectOption} from "./SpecTypeTitle";
import {removeSpecItem, updateSpecItem} from "../../store/actions/SpecsAction";

type SpecItemRowProps = {
  index: number;
};

const SpecItemRow: React.FC<SpecItemRowProps> = (props) => {

  const {index} = props;

  const [sampleSpecSelectOptions, setSampleSpecSelectOptions] = useState<SelectOption[]>([]);
  const [selectedSampleSpecOption, setSelectedSampleSpecOption] = useState<SelectOption>();

  const dispatch = useDispatch();

  const selectedSampleSpec: SampleSpec | null = useSelector((state: AppState) => state.spec.selectedSampleSpec);
  const spec: Spec | null = useSelector((state: AppState) => state.spec.spec);

  useEffect(() => {
    const sampleSpecOptions: SelectOption[] = [];
    selectedSampleSpec?.labels.map((item) => {
      sampleSpecOptions.push({value: item, label: item});
      return sampleSpecOptions;
    });
    setSampleSpecSelectOptions(sampleSpecOptions);
  }, [selectedSampleSpec]);

  useEffect(() => {
    if (!spec) {
      return;
    }
    const label = spec.specItems[index].label;
    setSelectedSampleSpecOption({
      value: label,
      label: label
    });
  }, [spec]);

  const handleOnSpecChange = (e: string) => {
    if (!spec) {
      return;
    }
    const specItemObject = spec.specItems[index];
    const specItemObjectWithNewValue = {...specItemObject, label: e};
    dispatch(updateSpecItem({index: index, specItem: specItemObjectWithNewValue}));
  };

  const handleTitleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!spec) {
      return;
    }
    const specItemObject = spec.specItems[index];
    const specItemObjectWithNewValue = {...specItemObject, description: e.target.value};
    dispatch(updateSpecItem({index: index, specItem: specItemObjectWithNewValue}));
  };

  return (
    <Row className="add-specs-select-sub-specs">
      <Col md={3} xs={10} className='mb-1 add-specs-select-sub-specs-select'>
        {selectedSampleSpecOption?.value === '' ?
          <CreatableSelect styles={customStylesInAddSubSpecsRow}
                           value={null}
                           onChange={(e: any) => handleOnSpecChange(e ? e.value: '')}
                           options={sampleSpecSelectOptions}
                           isClearable placeholder={"Select"}/> :
          <CreatableSelect styles={customStylesInAddSubSpecsRow}
                           value={selectedSampleSpecOption}
                           onChange={(e: any) => handleOnSpecChange(e ? e.value: '')}
                           options={sampleSpecSelectOptions}
                           isClearable placeholder={"Select"}/>}
      </Col>
      <Col md={3} xs={10} className='mb-1 add-specs-select-sub-specs-input'>
        <Form.Control id="inlineFormInputName" value={spec?.specItems[index].description}
                      onChange={handleTitleOnchange} placeholder="Description"
        />
      </Col>
      <Col md={1} xs={2} className="mt-auto mb-auto text-left text-danger pl-0 delete-spec">
        <i className='feather icon-trash' onClick={() => dispatch(removeSpecItem(index))}/>
      </Col>
    </Row>
  )
};
export default SpecItemRow;