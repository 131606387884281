
export const SET_SPEC_EDITOR_VISIBILITY = 'SET_SPEC_EDITOR_VISIBILITY';
export const ADD_SPEC_ITEM = 'ADD_SPEC_ITEM';
export const SET_SPEC_TITLE = 'SET_SPEC_TITLE';
export const UPDATE_SPEC_ITEM = 'UPDATE_SPEC_ITEM';
export  const REMOVE_SPEC_ITEM = 'REMOVE_SPEC_ITEM';
export  const SET_SAMPLE_SPECS = 'SET_SAMPLE_SPECS';
export  const SET_SELECTED_SAMPLE_SPEC = 'SET_SELECTED_SAMPLE_SPEC';
export  const SET_SPEC = 'SET_SPEC';
export const RESET_SPEC = 'RESET_SPEC';
