import React from 'react';
import {IProduct} from "../../../types/Types";
import { Link } from 'react-router-dom';
import {ROOT_PATH} from "../../../routes/dashboard";

type DeleteProductProps = {
    product: IProduct
}

const BtnUpdateProduct: React.FC<DeleteProductProps> = (props) => {

    return (
        <div className='tb-edit-icon mb-2'>
            <Link to={ROOT_PATH + `/products/update/${props.product._id}/${props.product.title}`}>
                <i className='feather icon-edit'/>
            </Link>
        </div>
    )
};

export default BtnUpdateProduct;