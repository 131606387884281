import gql from 'graphql-tag';
import {PRODUCT_FRAGMENT} from "../Mutations/Product";

export const GET_PRODUCTS = gql`
    {
      products{
        _id
        pid
        title
        product_code
        sub_title
        price{
          amount
        }
        category{
          main_category {
            _id
            mainCategory
          }
          sub_category{
            _id
            subCategory
          }
        }
      }
    }
`;

export const GET_PRODUCT = gql`
${PRODUCT_FRAGMENT}

query getProduct($id: String!) {
  product(id: $id) {
    ...productAll
  }
}`;

export const GET_PRODUCT_IMAGES = gql`
query getProductImages($id: String!) {
  product(id: $id) {
    _id
    business
    photos {
      main_photo
    }
  }
}
`;