import {
  AddNewSpecItem,
  RemoveSpecItem,
  ResetSpec,
  SampleSpec,
  SetSampleSpecs,
  SetSelectedSampleSpec,
  SetSpec,
  SetSpecEditorVisibility,
  SetSpecTitle,
  Spec,
  UpdateSpecItem,
  UpdateSpecItemPayload
} from "../../types/SpecStoreTypes";
import {
  ADD_SPEC_ITEM,
  REMOVE_SPEC_ITEM,
  RESET_SPEC,
  SET_SAMPLE_SPECS,
  SET_SELECTED_SAMPLE_SPEC,
  SET_SPEC,
  SET_SPEC_EDITOR_VISIBILITY,
  SET_SPEC_TITLE,
  UPDATE_SPEC_ITEM
} from "../../constants/actions/SpecActions";

/**
 * set spec editor visible on AddSpec label click.
 * @param isVisible - set this true to show the editor.
 */
export function setSpecEditorVisibility(isVisible: boolean): SetSpecEditorVisibility {
  return {
    type: SET_SPEC_EDITOR_VISIBILITY,
    payload: isVisible,
  };
}

/**
 * adding a new empty specItem when plus + button click.
 */
export function addNewSpecItem(): AddNewSpecItem {
  return {
    type: ADD_SPEC_ITEM,
  };
}

/**
 * set spec title when title is entered
 * @param title
 */
export function setSpecTitle(title: string): SetSpecTitle {
  return {
    type: SET_SPEC_TITLE,
    payload: title,
  };
}

/**
 * updates a single specItem when specItem data edited.
 * @param updatedSpecItemObj - object having index(specItem's place in the specItems array) and spec item.
 */
export function updateSpecItem(updatedSpecItemObj: UpdateSpecItemPayload): UpdateSpecItem {
  return {
    type: UPDATE_SPEC_ITEM,
    payload: updatedSpecItemObj,
  };
}

/**
 * remove specItem from specItems array, when delete x button click.
 * @param index - specItem's place in the specItems array.
 */
export function removeSpecItem(index: number): RemoveSpecItem {
  return {
    type: REMOVE_SPEC_ITEM,
    payload: index,
  };
}

/**
 * set sampleSpecs which are coming from the database
 * @param sampleSpecs - set of sampleSpecs
 */
export function setSampleSpecs(sampleSpecs: SampleSpec[]): SetSampleSpecs {
  return {
    type: SET_SAMPLE_SPECS,
    payload: sampleSpecs,
  };
}

/**
 * set selected sample spec, when select from the dropdown.
 * @param sampleSpec - sample spec selected
 */
export function setSelectedSampleSpec(sampleSpec: SampleSpec): SetSelectedSampleSpec {
  return {
    type: SET_SELECTED_SAMPLE_SPEC,
    payload: sampleSpec,
  };
}

export function setSpec(spec: Spec): SetSpec {
  return {
    type: SET_SPEC,
    payload: spec,
  };
}

export function resetSpec(): ResetSpec {
  return {
    type: RESET_SPEC,
  };
}




