import { API_URL } from "./ServerURL";

export const FE_ROUTES = {
  LOGIN: "/auth",
  LOGOUT: "/logout",
  DASHBOARD: "/dashboard",
  TERMS: "/terms",
  HOME: "/",
  ROOT: "/:title?",
  INVOICE: "/invoice/:id",
};

export const API_ROUTES = {
  LOGIN: API_URL + "/login",
  REGISTER: API_URL + "/register",
};
