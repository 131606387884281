import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import dashRoutes from "../../routes/dashboard";
import { Switch, Redirect, Route } from "react-router-dom";
import { STORAGE_USER } from "../../constants/StorageUser";
import { FE_ROUTES } from "../../constants/routes";

type DashboardProps = {};

const Dashboard: React.FC<any> = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState(FE_ROUTES.LOGIN);

  const authCheck = () => {
    const user = localStorage.getItem(STORAGE_USER);
    if (!user) {
      setRedirect(true);
      return;
    }
    const storeUser = JSON.parse(user);
    if (!storeUser.AG) {
      setRedirectPath(FE_ROUTES.TERMS);
    }
  };

  useEffect(() => {
    if (redirectPath === FE_ROUTES.TERMS) {
      setRedirect(true);
    }
  }, [redirectPath]);

  useEffect(() => {
    authCheck();
  }, []);

  const openSideBar = () => {
    document.documentElement.classList.toggle('nav-open');
    // document.documentElement.classList.toggle('toggled');
    // this.sidebarToggle.classList.toggle('toggled');
  };

  return (
    <div className="wrapper">
      {redirect && <Redirect to={redirectPath} />}
      <Sidebar
        {...props}
        bgColor={"white"}
        activeColor={"info"}
        routes={dashRoutes}
        openSideBar={openSideBar}
      />
      <div className="main-panel">
        <Header {...props} openSideBar={openSideBar}/>
        <div className="content">
          <Switch>
            {dashRoutes.map((prop: any, key: number) => {
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
