import React from "react";
import {BillingInfo} from "../../types/InvoiceTypes";
import {Row, Col} from "react-bootstrap";

type CurrentInvoiceCustomerProps = {
  customer: BillingInfo | undefined;
  invoiceId: number | undefined;
  billingInfo: BillingInfo;
};
const CurrentInvoiceCustomer: React.FC<CurrentInvoiceCustomerProps> = (props
) => {

  const displayDeliverTo = () => {
    if (billingInfo) {
      return (<Col xs={12} md={3} className="text-right mb-3">
        <p className="mb-0">Deliver to:</p>
        <p className="mb-0">{billingInfo?.fullName}</p>
        <p className="mb-0">{billingInfo?.address}</p>
        <p className="mb-0 d-inline">{billingInfo?.city}</p>, <p className="mb-0 d-inline">{billingInfo?.postcode}</p>
        <p className="mb-0">{billingInfo?.tel}</p>
      </Col>)
    }

    return (<Col xs={12} md={3} className="text-right mb-3">
      <p className="mb-0">Deliver to:</p>
      <p className="mb-0">{customer?.fullName}</p>
      <p className="mb-0">{customer?.address}</p>
      <p className="mb-0 d-inline">{customer?.city}</p>, <p className="mb-0 d-inline">{customer?.postcode}</p>
      <p className="mb-0">{customer?.tel}</p>
    </Col>)
  };

  const {customer, invoiceId, billingInfo} = props;
  return (
    <Row className="pt-3">
      <Col xs={9} className="customer-details text-success">
        <p className="customer-name text-success selected-customer">
          {customer && customer.fullName}
        </p>
      </Col>
      <Col xs={3}>
        {invoiceId && <p className="table-num">Invoice #: {invoiceId}</p>}
      </Col>
      <Col xs={12} md={6}/>
      <Col xs={12} md={3} className="text-left mb-3">
        <p className="mb-0">Billing:</p>
        <p className="mb-0">{customer?.fullName}</p>
        <p className="mb-0">{customer?.address}</p>
        <p className="mb-0 d-inline">{customer?.city}</p>, <p className="mb-0 d-inline">{customer?.postcode}</p>
        <p className="mb-0">{customer?.tel}</p>
      </Col>
      {displayDeliverTo()}
    </Row>
  );
};
export default CurrentInvoiceCustomer;
