import React from 'react';
import {Col, Form} from "react-bootstrap";

const ProductFeatureCategory: React.FC = () => {
    return (
        <React.Fragment>
            <Form.Label>Feature Category</Form.Label>
            <Form.Control id="inlineFormInputName" placeholder="feature category"
                // onChange={handleChangeFeatureCategory}
            />
        </React.Fragment>
    )
};

export default ProductFeatureCategory;