import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import Select from "react-select";
import {CategoryData, SelectCategory} from "../../../types/CategoryTypes";
import {setSelectedCategory} from "../../../store/actions/CategoryActions";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";

export interface MainCategoryProps {
    options: any;
    onCategoryChange: (id: string | null) => void;
}

const ProductMainCategory: React.FC<MainCategoryProps> = (props) => {
    const {options} = props;
    const [selectedOption, setSelectedOption] = useState<SelectCategory | null>(null);

    const selectedCategory: CategoryData | null = useSelector(
        (state: AppState) => state.categories.selectedCategory
    );

    useEffect(() => {
        let option = null;
        if(!selectedCategory) {
            setSelectedOption(null);
            return;
        }

        option = {id: selectedCategory._id, value: selectedCategory.mainCategory, label: selectedCategory.mainCategory};
        setSelectedOption(option);
    }, [selectedCategory]);

    const dispatch = useDispatch();

    const handleChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
        // console.log(selectedOption);
        if (selectedOption !== null) {
            props.onCategoryChange(selectedOption.id);
            return;
        }
        dispatch(setSelectedCategory(null));
    };

    return (
        <React.Fragment>
            {/*<Col md={12} lg={12} className='my-1'>*/}
            <Form.Label>Main Category</Form.Label>

            <Select
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={options}
                isClearable={true}
                name="Select"
                options={options}
                value={selectedOption}
                onChange={handleChange}
            />

            {/*</Col>*/}
        </React.Fragment>
    )
};

export default ProductMainCategory;