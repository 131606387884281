import React from "react";
import {Row} from "react-bootstrap";
import Checkbox from "rc-checkbox";

type TermsAndConditionsAgreeProps = {
  handleChange: (event: any) => void;
  checked: boolean;
};
const TermsAndConditionsAgree: React.FC<TermsAndConditionsAgreeProps> = (props
) => {
  return (
    <div>
      <Row>
        <label className={`pl-1 pr-1 pt-1 pl-md-3 pr-md-3 pt-md-3 ${props.checked ? "afterClick" : "beforeClick"} `}>
          <Checkbox className="pl-3 pr-1 pt-1 pl-md-2 pr-md-3 pt-md-3 mt-1"
                    name="I have read and accept the terms and conditions"
                    onChange={props.handleChange}
          />
          I have read and accept the terms and conditions
        </label>
      </Row>
    </div>
  );
};
export default TermsAndConditionsAgree;
