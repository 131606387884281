import gql from "graphql-tag";

const EDIT_BUSINESS_AGREEMENT = gql`
  mutation editBusinessAgreement($AG: Boolean) {
    editBusinessAgreement(AG: $AG) {
      _id
      business_name
    }
  }
`;

export { EDIT_BUSINESS_AGREEMENT };
