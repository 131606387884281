import {CategoryActionTypes, CategoryState,} from "../../types/CategoryStoreTypes";
import {
  SET_All_CATEGORY,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
} from "../../constants/actions/categoryActions";
import {CategoryData} from "../../types/CategoryTypes";

const categoryInitialState: CategoryState = {
  categories: [],
  selectedCategory: null,
  subCategory: null
};

export function categoryReducer(
  state = categoryInitialState,
  action: CategoryActionTypes
): CategoryState {
  switch (action.type) {
    case SET_All_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_SELECTED_CATEGORY:
      const categorySelected: CategoryData | null = action.payload;
      const allCategories: CategoryData[] = state.categories.slice();
      categorySelected &&
        allCategories.splice(
          allCategories.findIndex(
            (category) => category._id === categorySelected._id
          ),
          1,
          categorySelected
        );

      return {
        ...state,
        selectedCategory: action.payload,
        categories: categorySelected ? allCategories : state.categories,
      };

    case SET_SELECTED_SUB_CATEGORY: {
      return {
        ...state,
        subCategory: action.payload
      }
    }

    default: {
      return state;
    }
  }
}
