import { IRoute } from "../interface/IRoute";
import { FE_ROUTES } from "../constants/routes";
import Dashboard from "../views/layout/Dashboard";
import Auth from "../views/Auth/Auth";
import Logout from "../components/Auth/Logout";
import Home from "../views/Home";
import TermsAndConditions from "../views/TermsAndCoditions/TermsAndConditions";
import SingleInvoice from "../views/Invoice/SingleInvoice/SingleInvoice";

const indexRoutes: IRoute[] = [
  { path: FE_ROUTES.LOGIN, component: Auth },
  { path: FE_ROUTES.LOGOUT, component: Logout },
  { path: FE_ROUTES.TERMS, component: TermsAndConditions },
  { path: FE_ROUTES.INVOICE, component: SingleInvoice },
  // { path: FE_ROUTES.HOME, component: Auth }
  { path: FE_ROUTES.DASHBOARD, component: Dashboard },
  { path: FE_ROUTES.HOME, component: Home },
];

export default indexRoutes;
