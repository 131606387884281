import {AddPhotoUrl, DeletePhoto, RemovePhotoUrls, SetUploadPhotoUrlStatus} from "../../types/ProductTypes";
import {
  ADD_PHOTO_URL,
  DELETE_PHOTO,
  REMOVE_PHOTO_URLS,
  SET_UPLOAD_PHOTO_URL_STATUS
} from "../../constants/actions/productActions";

export function addPhotoUrl(photoUrl: string): AddPhotoUrl {
  return {
    type: ADD_PHOTO_URL,
    payload: photoUrl
  }
}

export function setUploadPhotoUrlStatus(isUploading: boolean): SetUploadPhotoUrlStatus {
  return {
    type: SET_UPLOAD_PHOTO_URL_STATUS,
    payload: isUploading
  }
}

export function removePhotoUrls(): RemovePhotoUrls {
  return {
    type: REMOVE_PHOTO_URLS
  }
}

export function deletePhoto(photoIndex: string): DeletePhoto {
  return {
    type: DELETE_PHOTO,
    payload: photoIndex
  }
}