import React, {useEffect, useState} from 'react';
import {Col, Form} from "react-bootstrap";
import ProductMainCategory from "./ProductMainCategory";
import ProductSubCategory from "./ProductSubCategory";
import ProductFeatureCategory from "./ProductFeatureCategory";
import {setCategories, setSelectedCategory as setSelectedCategoryStore} from "../../../store/actions/CategoryActions";
import {useDispatch, useSelector} from "react-redux";
import {CategoryData, SelectCategory} from "../../../types/CategoryTypes";
import {AppState} from "../../../store/reducers";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_CATEGORIES} from "../../../graphql/Queries/Category";

const ProductCategory: React.FC = (props) => {
    const [options, setOptions] = useState<SelectCategory[] | null>([]);
    // const [allCategories, setAllCategories] = useState<CategoryData[]>([]);
    const allCategories: CategoryData[] = useSelector((state: AppState) => state.categories.categories);


    const dispatch = useDispatch();

    const [getAllCategories, {data, loading, error}] = useLazyQuery(GET_CATEGORIES, {
        onError: error1 => {
            console.log('problem loading subcategories')
        }
    });

    useEffect(() => {
        if (allCategories.length > 0) {
            return;
        }

        getAllCategories();
    }, []);

    useEffect(() => {
        if (allCategories.length === 0) {
            return;
        }

        const categoryOptions: SelectCategory[] | null = allCategories.map(
            (category: CategoryData) => {
                return {
                    value: category.mainCategory,
                    label: category.mainCategory,
                    id: category._id,
                };
            }
        );
        if (categoryOptions === null) {
            return;
        }
        setOptions(categoryOptions);
    }, [allCategories]);



    useEffect(() => {
        if (!data) {
            return;
        }

        // const categoryOptions: SelectCategory[] | null = data.allCategories.map(
        //     (category: CategoryData) => {
        //         return {
        //             value: category.mainCategory,
        //             label: category.mainCategory,
        //             id: category._id,
        //         };
        //     }
        // );
        // if (categoryOptions === null) {
        //     return;
        // }
        // setOptions(categoryOptions);
        // setAllCategories(data.allCategories);

        dispatch(setCategories(data.allCategories));
    }, [data]);

    if (loading) return <div>
        <p>Loading...</p>
    </div>;

    if (error) return <p>Error...</p>;

    const handleOnCategorySelect = (id: string | null) => {
        const selectedCategoryIndex = allCategories.findIndex(
            (category) => category._id === id
        );
        dispatch(setSelectedCategoryStore(allCategories[selectedCategoryIndex]));
    };

    return (
        <div>
            <Form.Row className="align-items-center">
                <Col md={4} lg={4} className='my-1'>
                    <ProductMainCategory onCategoryChange={handleOnCategorySelect} options={options}/>
                </Col>
                <Col md={4} lg={4} className='my-1'>
                    {/*{ selectedCategory && <ProductSubCategory selectedCategory={selectedCategory}/> }*/}
                    <ProductSubCategory/>
                </Col>
                <Col md={4} lg={4} className='my-1'>
                    <ProductFeatureCategory/>
                </Col>
            </Form.Row>
        </div>
    )
};

export default ProductCategory;