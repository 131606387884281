import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Options from "./Options";
import { useMutation } from "@apollo/react-hooks";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";
import { EDIT_CATEGORY } from "../../graphql/Mutations/Category";
import { CategoryData } from "../../types/CategoryTypes";
import { setSelectedCategory } from "../../store/actions/CategoryActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import ImageUploadCategory from "./ImageUploadCategory";
import {
  retrieveFile,
  uploadImage,
} from "../../components/UploadToBucket/UploadToBucket";

type MainCategoryPreviewProps = {
  selectedCategory: CategoryData;
};

const MainCategoryPreview: React.FC<MainCategoryPreviewProps> = (props) => {
  const dispatch = useDispatch();
  const { selectedCategory } = props;
  const selecteCategory: CategoryData | null = useSelector(
    (state: AppState) => state.categories.selectedCategory
  );
  const [toggleCategoryInput, setToggleCategoryInput] = useState(true);
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [editCategory, setEditCategory] = useState<string>(
    selectedCategory.mainCategory
  );
  const [imageUrlWithKey, setImageUrlWithKey] = useState<string>("");

  const [updateCategory, {}] = useMutation(EDIT_CATEGORY, {
    onCompleted: (data) => {
      if (selecteCategory === null) {
        return;
      }
      dispatch(
        setSelectedCategory({ ...selecteCategory, mainCategory: editCategory })
      );
    },
    refetchQueries: ({ data: { addCategory } }) => [{ query: GET_CATEGORIES }],
  });

  const [deleteCategoryPhoto, {}] = useMutation(EDIT_CATEGORY, {
    onCompleted: (data) => {
      if (selecteCategory === null) {
        return;
      }

      dispatch(setSelectedCategory({ ...selectedCategory, photo: "" }));
    },
    refetchQueries: ({ data: {} }) => [{ query: GET_CATEGORIES }],
  });

  const [updateCategoryPhotoUpload, {}] = useMutation(EDIT_CATEGORY, {
    onCompleted: (data) => {
      if (selecteCategory === null) {
        return;
      }
      setUploadingStatus(false);
      dispatch(
        setSelectedCategory({
          ...selecteCategory,
          photo: data.editCategory.photo,
        })
      );
    },
    refetchQueries: ({ data: { addCategory } }) => [{ query: GET_CATEGORIES }],
  });

  useEffect(() => {
    setEditCategory(selectedCategory.mainCategory);
  }, [props.selectedCategory]);

  useEffect(() => {
    if (selecteCategory === null || selecteCategory.photo === "") {
      return;
    }

    retrieveFile(selecteCategory.photo).then((result) =>
      setImageUrlWithKey(result)
    );

    setEditCategory(selecteCategory.mainCategory);
  }, [selecteCategory]);

  const toggleCategoryInputs = () => {
    if (toggleCategoryInput) {
      setToggleCategoryInput(!toggleCategoryInput);
    }
  };

  const handleChange = (event: any) => {
    setEditCategory(event.target.value);
  };

  const onSubmitHandler = (event: any) => {
    event.preventDefault();
    setToggleCategoryInput(!toggleCategoryInput);
    updateCategory({
      variables: {
        _id: selectedCategory._id,
        mainCategory: editCategory,
      },
    });
  };
  const onBlurHandler = () => {
    setToggleCategoryInput(!toggleCategoryInput);
  };

  const handleOnPhotosUpload = (urls: string[]) => {
    setUploadingStatus(true);
    updateCategoryPhotoUpload({
      variables: {
        _id: selectedCategory._id,
        editPhoto: urls[0],
        mainCategory: selectedCategory.mainCategory,
      },
    });
  };

  const deleteSelectedCategoryImage = () => {
    deleteCategoryPhoto({
      variables: {
        _id: selectedCategory._id,
        editPhoto: "",
        mainCategory: selectedCategory.mainCategory,
      },
    });
  };

  const uploadImageStatus = () => {
    setUploadingStatus(true);
  };

  const imagePreview = () => {
    if (selectedCategory.photo === "") {
      return null;
    }

    return (
      <div>
        <div
          className="remove-passport"
          style={{ left: "78%" }}
          onClick={deleteSelectedCategoryImage}
        >
          x{/*<label className='pp-close'>x</label>*/}
        </div>

        <img
          className="mb-4"
          src={imageUrlWithKey}
          alt=""
          style={{ width: "60%" }}
        />
      </div>
    );
  };

  const toggleCategoryInputMethod = () => {
    if (toggleCategoryInput) {
      return (
        <div>
          <label className="text-center main-category-name">
            {editCategory}
          </label>
          <Options
            toggleCategoryInputs={toggleCategoryInputs}
            deleteCategoryId={selectedCategory._id}
          />
        </div>
      );
    }
    return (
      <Row>
        <Col md={{ span: 4, offset: 4 }} xs={12} className="">
          <Form className="mt-3" onSubmit={onSubmitHandler}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                style={{ borderWidth: "2.5px" }}
                type="text"
                autoFocus
                placeholder="Enter category"
                value={editCategory}
                onBlur={onBlurHandler}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    );
  };

  const imageUpload = () => {
    if (selectedCategory.photo === "") {
      if (uploadingStatus) {
        return "Uploading....";
      }
      return (
        <ImageUploadCategory
          onImageUpload={handleOnPhotosUpload}
          uploadImageStatus={uploadImageStatus}
        />
      );
    }
  };

  return (
    <div>
      <div className="main-category-bottom">
        <Row>
          <Col xs={12} className="pr-4 text-center">
            {toggleCategoryInputMethod()}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pr-4 text-center">
            {imagePreview()}
          </Col>
          <Col xs={12} className="pr-4 text-center">
            {imageUpload()}
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default MainCategoryPreview;
