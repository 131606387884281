import gql from "graphql-tag";

export const PRODUCT_FRAGMENT = gql`
    fragment productAll on Product{
        _id
        business
        product_code
        title
        sub_title
        category{
            main_category {
                _id
                mainCategory
            }
            sub_category{
                _id
                subCategory
            }
            feature_category
        }
        variety{
            v1
            v2
        }
        guide
        description{
            long_details
            specs
            video
        }
        availability{
            available
            timeout
            countries
        }
        price{
            amount
            discount {
                discount_type
                discount_amount
            }
        }
        specs{
            title
            specItems {
                label
                description
            }
        }
        tags
        shipping
        photos{
            main_thumb
            main_photo
        }
        stock
        remarks
    }
`;

export const CREATE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}

    mutation addProduct($business: String!, $product_code: String!, $title: String!, $sub_title: String,
        $category: CategoryInput, $variety: VarietyInput, $guide: String, $description: DescriptionInput,  $specs: [SpecInput],
        $availability: AvailabilityInput, $price: PriceInput, $tags: [String], $shipping: String, $photos: [PhotoInput], $stock: String, $remarks: String) {
        addProduct(business: $business, product_code: $product_code, title: $title, sub_title: $sub_title,
            category: $category, variety: $variety, guide: $guide, description: $description, availability: $availability,
            price: $price, tags: $tags, shipping: $shipping, photos: $photos, stock: $stock, remarks: $remarks, specs: $specs) {

            ...productAll
        }
    }
`;

export const DELETE_PRODUCT = gql`

    mutation deleteProduct($id: String!, $business:String!){
        deleteProduct(_id:$id, business:$business){
            _id
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    ${PRODUCT_FRAGMENT}

    mutation editProduct ($_id:String!, $pid: Int, $business: String!, $product_code: String, $title: String, $specs: [SpecInput],
        $category: CategoryInput, $description: DescriptionInput, $price: PriceInput, $tags: [String], $photos: [PhotoInput]) {
        editProduct (_id:$_id, pid:$pid,business:$business, product_code:$product_code, title:$title, category:$category,
            description:$description, price:$price, tags:$tags, photos:$photos, specs: $specs){
            ...productAll
        }
    }
`;