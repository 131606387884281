import axios from "axios";
import { S3_GET_URL, S3_PUT_URL } from "../../constants/ServerURL";

const BUCKET_URL = "https://shub-s3-res.s3.amazonaws.com/";

export const uploadImage = async (
  file: File | null,
  path?: string,
  bucket?: string,
  suffix?: string
) => {
  if (!file) {
    return;
  }
  const resPath = "res";
  const folderPath = path ? path : resPath;
  const bucketType = bucket ? bucket : null;

  const contentType = file.type; // eg. image/jpeg or image/svg+xml

  const fileNameParts = file.name.split(".");
  let filename = fileNameParts[0];
  let filenameExtension = "." + fileNameParts[fileNameParts.length - 1];
  const fileSuffix = suffix ? "_" + suffix : "";

  const generatePutUrl = S3_PUT_URL;

  filename =
    folderPath +
    "/" +
    filename.split(" ").join("-") +
    "_" +
    new Date().getTime().toString() +
    fileSuffix +
    filenameExtension;
  const options = {
    params: {
      Key: filename,
      ContentType: contentType,
    },
    headers: {
      "Content-Type": contentType,
    },
  };

  const generatePutRes = await axios.get(generatePutUrl, options);
  const {
    data: { putURL },
  } = generatePutRes;

  await axios
    .put(putURL, file, options)
    .then((res) => {})
    .catch((err: any) => {
      alert("Sorry, something went wrong");
    });

  return await retrieveFile(filename, bucketType);
};

export const retrieveFile = async (key: string, bucketType?: string | null) => {
  const filename = key;
  const generateGetUrl = S3_GET_URL;
  const options = {
    params: {
      Key: filename.replace(BUCKET_URL, ""),
    },
  };
  const res = await axios.get(generateGetUrl, options);

  const { data: getURL } = res;

  return getURL;
};
