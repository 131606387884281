import React from 'react';
import {Container, Row} from "react-bootstrap";
import PropTypes from "prop-types";

type FooterProps = {
    fluid?: boolean
    getYear?: Date
    default?: any
}

const Footer: React.FC<FooterProps> = (props) => {
    // const [fluid, setFluid] = useState(true);

    return (
        <footer
            className={"footer" + (props.default ? " footer-default" : "")}
        >
        <Container fluid={props.fluid ? true : false}>
            <Row>
                <nav className="footer-nav">
                    <ul>
                        <li>
                            <a href="http://www.softvessel.com" target="_blank">SoftVessel</a>
                        </li>
                        <li>
                            <a href="http://www.softvessel.com" target="_blank">Blog</a>
                        </li>
                        <li>
                            <a href="http://www.softvessel.com" target="_blank">Licenses</a>
                        </li>
                    </ul>
                </nav>
                <div className="credits text-center">
                    <div className="copyright">
                        &copy; {new Date().getFullYear()}, made with <i className="fa fa-heart heart"/> by SoftVessel
                    </div>
                </div>
            </Row>
        </Container>
        </footer>
    )
};

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool
};


export default Footer;