import React, {ChangeEvent, useEffect, useState} from 'react';
import Select from "react-select";
import {Col, Form, Row} from "react-bootstrap";
import {customStylesInSelectMainSpecType} from "../../constants/CreateProduct";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@apollo/react-hooks";
import {GET_SAMPLE_SPECS} from "../../graphql/Queries/SampleSpec";
import {setSampleSpecs, setSelectedSampleSpec, setSpecTitle} from "../../store/actions/SpecsAction";
import {SampleSpec, Spec} from "../../types/SpecStoreTypes";
import {AppState} from "../../store/reducers";

export type SelectOption = {
  value: string,
  label: string
};

const SpecTypeTitle: React.FC = () => {

  const [sampleSpecSelectOptions, setSampleSpecSelectOptions] = useState<SelectOption[]>([]);
  const [selectedSampleSpecOption, setSelectedSampleSpecOption] = useState<SelectOption>();

  const dispatch = useDispatch();
  const {data} = useQuery(GET_SAMPLE_SPECS);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (!data.getSampleSpecs) {
      return;
    }
    dispatch(setSampleSpecs(data.getSampleSpecs));
  }, [data]);

  const spec: Spec | null = useSelector((state: AppState) => state.spec.spec);
  const sampleSpecs: SampleSpec[] = useSelector((state: AppState) => state.spec.sampleSpecs);
  const selectedSampleSpec: SampleSpec | null = useSelector((state: AppState) => state.spec.selectedSampleSpec);

  const handleTitleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSpecTitle(e.target.value));
  };

  useEffect(() => {
    const sampleSpecsTitleOptions: SelectOption[] = [];
    sampleSpecs.map((item) => {
      sampleSpecsTitleOptions.push({value: item.categoryTitle, label: item.categoryTitle});
      return sampleSpecsTitleOptions;
    });
    setSampleSpecSelectOptions(sampleSpecsTitleOptions);
  }, [sampleSpecs]);

  useEffect(() => {
    if (!selectedSampleSpec) {
      return;
    }
    setSelectedSampleSpecOption({
      value: selectedSampleSpec.categoryTitle,
      label: selectedSampleSpec.categoryTitle
    });
  }, [selectedSampleSpec]);


  const handleOnCategoryTitleChange = (e: string) => {
    const selectedSampleSpec = sampleSpecs.find((item) => item.categoryTitle === e);
    if (!selectedSampleSpec) {
      return;
    }
    dispatch(setSelectedSampleSpec(selectedSampleSpec));
  };

  return (
    <Row className="add-specs-select-main-specs">
      <Col md={3} xs={10} className='mt-sm-1 mb-sm-1 mt-md-2 pt-1 pb-1 mb-md-2'>
        {spec && <Form.Control id="inlineFormInputName" placeholder="Title" value={spec.title}
                               onChange={handleTitleOnchange}/>}
      </Col>
      <Col md={3} xs={10} className='mt-2 pt-1 pb-1 mb-2 add-specs-select-sub-specs-select'>
        <Select styles={customStylesInSelectMainSpecType}
                value={selectedSampleSpecOption}
                onChange={(e: any) => handleOnCategoryTitleChange(e.value)}
                options={sampleSpecSelectOptions}
                placeholder={"Select"}/>
      </Col>
    </Row>
  )
};
export default SpecTypeTitle;