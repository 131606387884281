import React from 'react';
import {Col, Image} from "react-bootstrap";
import * as url from "../../assets/img/spencehub.2055e931.png";

const TermsAndConditionsHeader: React.FC = () => {
  return (
    <React.Fragment>
      <Col xs={6} md={2} className="mt-1 ml-1 mt-md-3 ml-md-5 terms-and-conditions-card-image ">
        <Image src={url.default} alt=""/>
      </Col>
      <Col md={8}/>
      <Col md={12} className="mt-1 ml-1 mt-md-3 ml-md-5 font-weight-bolder terms-and-conditions-title">
        <h3>Terms and Conditions</h3>
      </Col>
      <Col md={12} className="mt-0 ml-1 ml-md-4 terms-and-conditions-effective-col">
        <p className="ml-md-4 pl-0 terms-and-conditions-effective">
          Effective as of July 31, 2020
        </p>
      </Col>
    </React.Fragment>
  )
};
export default TermsAndConditionsHeader;