import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreateMainCategory from "./CreateMainCategory";
import { CategoryData, SelectCategory } from "../../types/CategoryTypes";
import { setSelectedCategory } from "../../store/actions/CategoryActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";

export interface MainCategoryProps {
  options: any;
  onCategoryChange: (id: string | null) => void;
}

const MainCategorySelect: React.FC<MainCategoryProps> = (props) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<SelectCategory | null>(
    null
  );
  const selectedCategory: CategoryData | null = useSelector(
    (state: AppState) => state.categories.selectedCategory
  );

  useEffect(() => {
    selectedCategory === null
      ? setSelectedOption(null)
      : setSelectedOption({
          label: selectedCategory.mainCategory,
          value: selectedCategory.mainCategory,
          id: selectedCategory._id,
        });
  }, [selectedCategory]);

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    if (selectedOption !== null) {
      props.onCategoryChange(selectedOption.id);
      return;
    }
    dispatch(setSelectedCategory(null));
  };

  return (
    <div className="pl-5 pr-5 pt-3 pb-3">
      <h3 className="pb-4">Main Category</h3>

      <Select
        className="basic-single pb-3"
        classNamePrefix="select"
        defaultValue={props.options[0]}
        isClearable={true}
        name="Select"
        options={props.options}
        value={selectedOption}
        onChange={handleChange}
      />

      <CreateMainCategory type="Main" />
    </div>
  );
};

export default MainCategorySelect;
