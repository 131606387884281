import React, { FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { ADD_CATEGORY } from "../../graphql/Mutations/Category";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";
import { setSelectedCategory } from "../../store/actions/CategoryActions";
import { useDispatch } from "react-redux";

interface CreateMainCategoryProps {
  type: string;
}

const CreateMainCategory: React.FC<CreateMainCategoryProps> = (props) => {
  const dispatch = useDispatch();

  const [createCategory, { data: addData, loading, error }] = useMutation(
    ADD_CATEGORY,
    {
      onCompleted: (data) => {
        dispatch(setSelectedCategory(data.addCategory));
        setNewMainCategory("");
      },
      refetchQueries: ({ data: { addCategory } }) => [
        { query: GET_CATEGORIES },
      ],
    }
  );

  const [toggleCategoryInput, setToggleCategoryInput] = useState(true);
  const [newMainCategory, setNewMainCategory] = useState("");
  const toggleCategoryInputs = () => {
    if (toggleCategoryInput) {
      setToggleCategoryInput(!toggleCategoryInput);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMainCategory(event.target.value);
  };

  const onSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    setToggleCategoryInput(!toggleCategoryInput);
    if (props.type === "Sub") {
      return;
    }

    createCategory({
      variables: {
        mainCategory: newMainCategory,
        photo: "",
      },
    });
  };
  const onBlurHandler = () => {
    setToggleCategoryInput(!toggleCategoryInput);
    setNewMainCategory("");
  };

  let sub = "";
  if (props.type === "Sub") {
    sub = "create-main-category-line-sub";
  }
  let classCombine = `create-main-category-line ${sub}`;
  return (
    <div onClick={toggleCategoryInputs} style={{ cursor: "pointer" }}>
      {toggleCategoryInput ? (
        <div
          className="create-main-category "
          style={
            props.type === "Sub"
              ? { textAlign: "left", marginTop: "5%" }
              : { textAlign: "left", margin: "5%", marginLeft: "0%" }
          }
        >
          <p className={classCombine}>
            <i className="feather icon-plus mr-1" />
            Create {props.type} Category
          </p>
        </div>
      ) : (
        <Form className="mt-3" onSubmit={onSubmitHandler}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              style={{ borderWidth: "2.5px" }}
              type="text"
              autoFocus
              placeholder="Enter category"
              value={newMainCategory}
              onBlur={onBlurHandler}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      )}
    </div>
  );
};
export default CreateMainCategory;
