import React, {useEffect} from 'react';

const TableCustomSearch: React.FC<any> = (props) => {

  useEffect(() => {
    props.onSearch(props.query);
  }, [props.query]);
  return (
    <React.Fragment/>
  );
};

export default TableCustomSearch;