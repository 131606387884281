import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {CategoryData, SelectCategory, SubCategory, SubCategory as SubCategoryType} from "../../../types/CategoryTypes";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setSelectedSubCategory} from "../../../store/actions/CategoryActions";

// type ProductSubCategoryProps = {
//     selectedCategory?: CategoryData;
// };

const ProductSubCategory: React.FC = (props) => {
    const selectedCategory: CategoryData | null = useSelector((state: AppState) =>
        state.categories.selectedCategory);
    const selectedSubCategory: SubCategory | null = useSelector((state: AppState) =>
        state.categories.subCategory);

    const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState<SelectCategory | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedCategory) {
            setSelectedOption(null);
            setSubCategoryOptions([]);

            return
        }

        const options = selectedCategory.subCategories.map(
            (subCategory: SubCategoryType) => {
                return {
                    value: subCategory.subCategory,
                    label: subCategory.subCategory,
                    id: subCategory._id
                }
            }
        );

        setSubCategoryOptions(options);
    }, [selectedCategory]);

    useEffect(() => {
        let option = null;
        if(!selectedSubCategory) {
            setSelectedOption(null);
            return;
        }

        option = {id: selectedSubCategory._id, value: selectedSubCategory.subCategory,
            label: selectedSubCategory.subCategory};
        setSelectedOption(option);
    }, [selectedSubCategory]);

    const handleChange = (selectedOption: any) => {
        // setSelectedOption(selectedOption);
        // console.log(selectedOption);
        if (selectedOption !== null) {
            const subCat: SubCategory = {_id: selectedOption.id, subCategory: selectedOption.value, orderNum: null};
            dispatch(setSelectedSubCategory(subCat));
            return;
        }
        dispatch(setSelectedSubCategory(null));
    };


    return (
        <React.Fragment>
            {/*<Col md={12} lg={12} className='my-1'>*/}
            <Form.Label>Sub Category</Form.Label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                // defaultValue={props.options}
                isClearable={true}
                name="Select"
                options={subCategoryOptions}
                value={selectedOption}
                onChange={handleChange}
            />
        </React.Fragment>
    )
};

export default ProductSubCategory;