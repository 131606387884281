import React, {useState} from 'react';
import {Button, InputGroup, Form} from "react-bootstrap";
import {Redirect, NavLink} from 'react-router-dom';
import {API_ROUTES} from "../../constants/routes";
import axios from "axios";

export const usernameRegex = /^[a-zA-Z0-9\-]+$/;

const Register: React.FC = () => {
    const [signUpState, setSignUpState] = useState<SignUpState>({
        // username: null,
        password: null,
        email: null,
        isUsernameInvalid: false,
        isLoginError: false,
        tocAgreed: false,
        message: null,
        redirect: false,
        processing: false
    });

    // const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     if (value.match(usernameRegex) || value === '') {
    //         setSignUpState({...signUpState, username: event.target.value, isUsernameInvalid: false});
    //         return;
    //     }
    //
    //     setSignUpState({...signUpState, isUsernameInvalid: true});
    // };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignUpState({...signUpState, password: event.target.value});
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignUpState({...signUpState, email: event.target.value});
    };

    const handleOnTermsAgreementChange = () => {
        setSignUpState({...signUpState, tocAgreed: !signUpState.tocAgreed});
    };

    const handleRegistration = (data: any) => {
        // const user = { auth: true, token: "a", role: "admin" };
        const user = {
            auth: data.auth,
            token: data.token,
            role: data.role,
            name: data.name
        };

        // if (
        //   user.role !== USER_ROLES.SUPER_ADMIN &&
        //   user.role !== USER_ROLES.AGENCY_ADMIN &&
        //   user.role !== USER_ROLES.AGENCY_USER
        // ) {
        //   setSignUpState({
        //     ...signUpState,
        //     message: "Unauthorized user account!",
        //     isLoginError: true
        //   });
        //   return;
        // }

        // localStorage.setItem(STORAGE_USER, JSON.stringify(user));

        // if (this.props.history.length > 1) {
        //   this.props.history.goBack();
        // }

        // if (data.role === ROLES.owner) {
        //   this.props.history.push("/");
        // } else {
        //   //role agent
        //   this.props.history.push("/pos");
        // }

        setSignUpState({...signUpState, redirect: true});
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSignUpState({
            ...signUpState,
            message: "",
            isLoginError: false,
            processing: true
        });

        if (!signUpState.tocAgreed) {
            alert('please check \'I agree terms & conditions\' to continue');
            setSignUpState({
                ...signUpState,
                processing: false
            });
            return;
        }

        if (signUpState.isUsernameInvalid) {
            return;
        }

        const auth = {
            // username: signUpState.username,
            password: signUpState.password,
            role: "super_admin",
            username: signUpState.email,
            login_type: "password"
        };

        axios
            .post(API_ROUTES.REGISTER, auth)
            .then((res: any) => {
                if (res.data.auth) {
                    setSignUpState({...signUpState, isLoginError: false});
                    handleRegistration(res.data);
                }
            })
            .catch((err: any) => {
                if (!err.response) {
                    setSignUpState({
                        ...signUpState,
                        message: "Problem occurred. Try again!",
                        isLoginError: true
                    });
                }

                const message = err.response.data;
                localStorage.removeItem("user");

                if (err.response.status === 403) {
                    setSignUpState({
                        ...signUpState,
                        message: message,
                        isLoginError: true
                    });
                }

                if (err.response.status === 400) {
                    setSignUpState({
                        ...signUpState,
                        message: "Problem occurred. Try again!",
                        isLoginError: true
                    });
                }
            });
    };

    const renderRedirect = () => {
        if (signUpState.redirect) {
            return <Redirect to={"/auth/login"}/>;
        }
    };

    return (
        <React.Fragment>
            {renderRedirect()}
            <div className='login-dialog'>
                <h3>Register</h3>

                <Form className='login-form' onSubmit={handleSubmit}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <i className='nc-icon nc-email-85'/>
                        </InputGroup.Prepend>
                        <Form.Control type='email' name='email' placeholder="E-mail" onChange={handleEmailChange}
                                      required
                        />
                    </InputGroup>

                    <InputGroup>
                        <InputGroup.Prepend>
                            <i className='nc-icon nc-lock-circle-open'/>
                        </InputGroup.Prepend>
                        <Form.Control className='form-style' type='password' name='password' placeholder="Password"
                                      onChange={handlePasswordChange}
                                      required
                        />
                    </InputGroup>

                    {/*<InputGroup>*/}
                    {/*    <InputGroup.Prepend>*/}
                    {/*        <i className='nc-icon nc-lock-circle-open'/>*/}
                    {/*    </InputGroup.Prepend>*/}
                    {/*    <Form.Control className='form-style' type='password' name='password'*/}
                    {/*                  placeholder="Re-type Password"/>*/}
                    {/*</InputGroup>*/}

                       <Form.Group>
                           <Form.Check
                               type="checkbox"
                               id="customControlAutosizing"
                               label={<p>I agree the <a href="#!">terms & conditions</a> of Spence Hub.</p>}
                               custom
                               defaultChecked={signUpState.tocAgreed}
                               onChange={handleOnTermsAgreementChange}
                           />
                       </Form.Group>

                    <div className='error-container'>
                        {signUpState.isLoginError && (
                            <p className="login-error">{signUpState.message}</p>
                        )}

                        <div className="login ml-auto mr-auto">
                            <Button variant="primary" className='btn-block' type='submit' disabled={signUpState.processing}>
                                {/*{signUpState.processing && getLoadingSpan()}*/}
                                Register
                            </Button>
                        </div>
                        <hr className='divider'/>
                        <div>
                            <p className='developer'> &copy; {new Date().getFullYear()} developed by <a
                                href="http://www.softvessel.com" target="_blank"> SoftVessel</a></p>
                        </div>
                    </div>
                </Form>

                <p className="mb-0 text-muted">
                    Already have an account? <NavLink to="/auth/login">Login</NavLink>
                </p>
            </div>
        </React.Fragment>
    )
};

interface SignUpState {
    // username: string | null;
    password: string | null;
    email: string | null;
    isLoginError: boolean;
    isUsernameInvalid: boolean;
    message: string | null;
    redirect: boolean;
    tocAgreed: boolean;
    processing: boolean;
}

export default Register;