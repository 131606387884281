import gql from "graphql-tag";

export const INVOICE_FRAGMENT = gql`
  fragment invoiceAll on Invoice {
    invoice_id
    business
    personalInfo {
      _id
      fullName
      address
      city
      postcode
      country
      tel
      type
    }
    billingInfo {
      _id
      fullName
      address
      city
      postcode
      country
      tel
      type
    }
    discount
    total
    total_paid
    cost
    tax
    service_charge
    cash
    balance
    items {
      product {
        title
        product_code
        price {
          amount
          discount {
            discount_type
            discount_amount
          }
        }
      }
    }
    payType
    invoice_type
    remarks
    createdAt
    updatedAt
  }
`;

export const GET_INVOICES = gql`
  query invoices($fromDate: String, $toDate: String) {
    invoices(fromDate: $fromDate, toDate: $toDate) {
      _id
      invoice_id
      personalInfo {
        fullName
      }
      billingInfo {
        fullName
      }
      total
      createdAt
    }
  }
`;

export const GET_INVOICE = gql`
  query invoice($invoice_id: String!) {
    invoice(invoice_id: $invoice_id) {
      invoice_id
      business {
        _id
        business_name
        address
        description
        logoUrl
        tel
      }
      shipping
      personalInfo {
        _id
        fullName
        address
        city
        postcode
        country
        tel
        type
      }
      billingInfo {
        _id
        fullName
        address
        city
        postcode
        country
        tel
        type
      }
      discount
      total
      total_paid
      cost
      tax
      service_charge
      cash
      balance
      items {
        qty
        product {
          title
          product_code
          price {
            amount
            discount {
              discount_type
              discount_amount
            }
          }
        }
      }
      payType
      createdAt
      invoice_type
    }
  }
`;
