import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SubCategoryItem from "./SubCategoryItem";
import {
  CategoryData,
  SubCategory as SubCategoryType,
} from "../../types/CategoryTypes";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../../store/actions/CategoryActions";
import CreateSubCategory from "./CreateSubCategory";
import { AppState } from "../../store/reducers";
import { useMutation } from "@apollo/react-hooks";
import { EDIT_SUB_CATEGORY } from "../../graphql/Mutations/Category";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "lightblue" : "#dfe4ea",
  padding: grid,
  width: "100%",
});

type SubCategoryProps = {
  selectedCategory: CategoryData;
};

const SubCategory: React.FC<SubCategoryProps> = (props) => {
  const { selectedCategory } = props;
  const store = useSelector((state: AppState) => state);
  const dispatch = useDispatch();

  const [changeOrderSubCategory, { data: addData }] = useMutation(
    EDIT_SUB_CATEGORY,
    {
      onCompleted: (data) => {},
      refetchQueries: ({ data: { addCategory } }) => [
        { query: GET_CATEGORIES },
      ],
    }
  );

  const onDragEnd = (result: any) => {
    if (!result.destination || !selectedCategory.subCategories) {
      return;
    }

    const items: any[] = reorder(
      selectedCategory.subCategories,
      result.source.index,
      result.destination.index
    );

    items.map((item: any) => {
      if (item.__typename) {
        delete item.__typename;
        return item;
      }
      return item;
    });

    changeOrderSubCategory({
      variables: {
        _id: store.categories.selectedCategory?._id,
        subCategories: items,
      },
    });

    dispatch(
      setSelectedCategory({ ...selectedCategory, subCategories: items })
    );
  };

  if (selectedCategory.subCategories === undefined) {
    return <React.Fragment />;
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <h3 className="m-3 sub-category-main-category-name">
              {selectedCategory.mainCategory}
            </h3>

            {selectedCategory.subCategories &&
              selectedCategory.subCategories.map(
                (item: SubCategoryType, index: number) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        style={{
                          cursor: "pointer",
                          backgroundColor: "red",
                          ...provided.draggableProps.style,
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SubCategoryItem
                          title={item.subCategory}
                          subCategoryId={item._id}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              )}
            {provided.placeholder}
            <CreateSubCategory type="Sub" />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SubCategory;
