import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

type DeleteCategoryProps = {
  deleteFunction: () => void;
  status: boolean;
};

const DeleteCategoryModel: React.FC<DeleteCategoryProps> = (props) => {
  const [show, setShow] = useState(props.status);

  useEffect(() => {
    setShow(props.status);
  }, [props]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.deleteFunction();
    handleClose();
  };

  return (
    <React.Fragment>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          {/*<Modal.Header closeButton/>*/}
          <div className="del-icon-info">
            <i className="feather icon-info" />
          </div>
          <Modal.Body>
            <div className="md-body">
              <p>Are you sure you want to delete this product?</p>
            </div>
            <div className="text-center">
              <Button
                variant="secondary"
                className="product-cancel-btn"
                onClick={handleClose}
              >
                No, Cancel
              </Button>
              <Button
                variant="primary"
                className="product-del-btn"
                onClick={handleClick}
              >
                Yes, Delete it
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="tb-trash-icon">
        {/*<i className="feather icon-trash" onClick={handleShow} />*/}
      </div>
    </React.Fragment>
  );
};
export default DeleteCategoryModel;
