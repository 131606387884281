import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";

type BtnViewInvoiceProps = {
  id: string;
};

const BtnViewInvoice: React.FC<BtnViewInvoiceProps> = (props) => {
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // deleteProduct({
    //   variables: {id: product._id, business: BUSINESS_ID},
    //   optimisticResponse: true,
    //   update: (cache) => {
    //     const exitingProducts = cache.readQuery({query: GET_PRODUCTS});
    //     // @ts-ignore
    //     const newProducts = exitingProducts.products.filter(p => (p._id !== product._id));
    //     cache.writeQuery({
    //       query: GET_PRODUCTS,
    //       data: {products: newProducts}
    //     })
    //   }
    // });
  };

  return (
    <React.Fragment>
      <div className="tb-view-icon">
        <NavLink target="_blank" to={`/invoice/${props.id}`}>
          <i className="feather icon-eye" />
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default BtnViewInvoice;
