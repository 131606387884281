export const customStylesInAddSubSpecsRow = {
  menu: (provided: any, state: any) => ({
    ...provided,
    fontSize:'0.8571em'
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    // paddingBottom:'5px',
    fontSize:'0.8571em'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    height:25,
    fontSize: '.8rem',
    minHeight: 30
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 2
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: 30,
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: 2,
      paddingBottom: 0
    };
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      paddingTop: 0,
      // paddingBottom: 0
    };
  },
  input: (provided: any, state: any) => {
    return {
      ...provided,
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0
    };
  },
};

export const customStylesInSelectMainSpecType = {
  menu: (provided: any, state: any) => ({
    ...provided,
    fontSize:'0.8571em'
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    paddingBottom:'5px',
    fontSize:'0.8571em'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    height:25,
    minHeight: 33
  }),
};

