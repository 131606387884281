import React, {FormEvent, useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {Form} from "react-bootstrap";
import {EDIT_SUB_CATEGORY} from "../../graphql/Mutations/Category";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {
    CategoryData,
    SubCategory,
    SubCategoryInput,
} from "../../types/CategoryTypes";
import {setSelectedCategory} from "../../store/actions/CategoryActions";

interface CreateMainCategoryProps {
    type: string;
}

const CreateSubCategory: React.FC<CreateMainCategoryProps> = (props) => {
    const selectedCategory: CategoryData | null = useSelector(
        (state: AppState) => state.categories.selectedCategory
    );

    const [toggleCategoryInput, setToggleCategoryInput] = useState(true);
    const [newSubCategory, setNewSubCategory] = useState("");
    const dispatch = useDispatch();

    const [createSubCategory, {}] = useMutation(EDIT_SUB_CATEGORY, {
        onCompleted: (data) => {
            if (!selectedCategory) {
                return;
            }

            const subCategories: SubCategory[] = data.editSubCategory.subCategories;
            dispatch(
                setSelectedCategory({
                    ...selectedCategory,
                    subCategories: subCategories,
                })
            );
        },
    });

    const toggleCategoryInputs = () => {
        if (toggleCategoryInput) {
            setToggleCategoryInput(!toggleCategoryInput);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSubCategory(event.target.value);
    };

    const onSubmitHandler = (event: FormEvent) => {
        event.preventDefault();

        setToggleCategoryInput(!toggleCategoryInput);

        if (selectedCategory === null) {
            return;
        }

        const newSubCategoryInput: SubCategoryInput = {
            subCategory: newSubCategory,
        };

        const subCategoryInputs: SubCategoryInput[] = selectedCategory.subCategories.map(
            (subCategory) => {
                const subCategoryInput: SubCategoryInput = {
                    _id: subCategory._id,
                    subCategory: subCategory.subCategory,
                };
                return subCategoryInput;
            }
        );

        subCategoryInputs.push(newSubCategoryInput);

        createSubCategory({
            variables: {
                _id: selectedCategory._id,
                subCategories: subCategoryInputs,
            },
        });

        setNewSubCategory("");
    };
    const onBlurHandler = () => {
        setToggleCategoryInput(!toggleCategoryInput);
        setNewSubCategory("");
    };

    let sub = "";
    if (props.type === "Sub") {
        sub = "create-main-category-line-sub";
    }
    let classCombine = `create-main-category-line ${sub}`;

    const toggleCategoryInputFunction = () => {
        if (toggleCategoryInput) {
            return (
                <div
                    className="create-main-category "
                    style={
                        props.type === "Sub"
                            ? {textAlign: "left", marginTop: "5%"}
                            : {textAlign: "left", margin: "5%", marginLeft: "0%"}
                    }
                >
                    <p className={classCombine}>
                        <i className="feather icon-plus mr-1"/>
                        Create {props.type} Category
                    </p>
                </div>
            );
        }

        return (
            <Form className="mt-3" onSubmit={onSubmitHandler}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control
                        style={{borderWidth: "2.5px"}}
                        type="text"
                        autoFocus
                        placeholder="Enter category"
                        value={newSubCategory}
                        onBlur={onBlurHandler}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Form>
        );
    };

    return (
        <div onClick={toggleCategoryInputs} style={{cursor: "pointer"}}>
            {toggleCategoryInputFunction()}
        </div>
    );
};
export default CreateSubCategory;
