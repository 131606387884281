import React from 'react';
import SpecTypeTitle from "./SpecTypeTitle";
import AddSpecLabel from "./AddSpecLabel";
import SpecItemList from "./SpecItemList";
import AddSpecItem from "./AddSpecItem";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {SampleSpec, Spec as SpecType} from "../../types/SpecStoreTypes";

const Spec: React.FC = () => {

  const visibility: boolean = useSelector((state: AppState) => state.spec.isSpecEditorVisible);
  const selectedSampleSpec: SampleSpec | null = useSelector((state: AppState) => state.spec.selectedSampleSpec);
  const spec: SpecType | null = useSelector((state: AppState) => state.spec.spec);

  return (
    <React.Fragment>
      {
        spec && spec.title.length > 0 ?
          <React.Fragment>
            <AddSpecLabel/>
            {visibility &&
            <React.Fragment>
                <SpecTypeTitle/>
                <React.Fragment>
                    <SpecItemList/>
                    <AddSpecItem/>
                </React.Fragment>
            </React.Fragment>}
          </React.Fragment> :
          <React.Fragment>
            <AddSpecLabel/>
            {visibility &&
            <React.Fragment>
                <SpecTypeTitle/>
              {selectedSampleSpec &&
              <React.Fragment>
                  <SpecItemList/>
                  <AddSpecItem/>
              </React.Fragment>}
            </React.Fragment>}
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default Spec;