import React from 'react';
import axios from 'axios';
import {API_URL} from "../../constants/ServerURL";

const Logout: React.FC<any> = (props) => {
    axios.get(API_URL + '/logout').then(res => {
        localStorage.removeItem('user');
        props.history.push('/');

    }).catch(err => {
        // const message = err.response.data;
        localStorage.removeItem('user');
        props.history.push('/');

        if(err.response.status === 403) {
            return <p>Problem occurred. try again</p>
            // setMessage({message: message, isLoginError: true});
        }

        if(err.response.status === 400) {
            // this.setState({message: 'Problem occurred. Try again!', isLoginError: true});
        }
    });
    return null;
};

export default Logout;