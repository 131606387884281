import React, {useState} from 'react';
import {Collapse, Image, Nav, Navbar} from "react-bootstrap";
import {Switch, Route, Link} from 'react-router-dom';
import Login from "../../components/Auth/Login";
import Register from "../../components/Auth/Register";
import LockScreen from "../../components/Auth/LockScreen";
import logo from '../../assets/img/logo.png';

const Auth: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
      <div className='auth-page'>
          <Navbar color="" variant='light' expand="md"
                  className='navbar-absolute fixed-top navbar-expand-lg nav-styles'>
              <div className='container'>
                  <Navbar.Brand href="/">
                      <Image src={logo}
                             alt='SpenceHub'
                             width="auto"
                             height="80"
                      />
                  </Navbar.Brand>
                  <Navbar.Toggle onClick={toggle}/>
                  {/*<Collapse isOpen={isOpen} navbar>*/}
                  <Nav className="ml-auto auth-nav-items" navbar>
                      <Nav.Item>
                          <Link to={'/auth/login'}>
                              <i className='nc-icon nc-tap-01'/>
                              Login
                          </Link>
                      </Nav.Item>
                      <Nav.Item>
                          <Link to={'/auth/register'}>
                              <i className='nc-icon nc-circle-10'/>
                              Register
                          </Link>
                      </Nav.Item>
                      <Nav.Item>
                          <Link to={'/auth/lock'}>
                              <i className='nc-icon nc-key-25'/>
                              Lock
                          </Link>
                      </Nav.Item>
                  </Nav>
                  {/*</Collapse>*/}
              </div>
          </Navbar>

          <div className='full-page'>
              <div className='full-page-inner'>
                  <div className='container'>
                      <Switch>
                          <Route path={'/auth/login'} component={Login}/>
                          <Route path={'/auth/register'} component={Register}/>
                          <Route path={'/auth/lock'} component={LockScreen}/>
                          <Route path={'/auth'} component={Login}/>

                      </Switch>
                  </div>
              </div>

              <div className='page-bg'/>
          </div>
      </div>
    )
};

export default Auth;