import React, {useEffect, useState} from 'react';
import {retrieveFile, uploadImage} from "../UploadToBucket/UploadToBucket";
import {Image, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {addPhotoUrl, deletePhoto} from "../../store/actions/ProductActions";
import {AppState} from "../../store/reducers";
import {UPLOAD_STATUS} from "../../constants/common";
import {ImageResizeOptions} from "../../types/ImageResize";
import {resizeFile} from "../../util/common";

export type ThumbProps = {
  photoFile: PhotoFileType
  index: number
}

export type PhotoFileType = {
  name?: string
  preview?: string
  status?: string
  file?: File
}

const Thumb: React.FC<ThumbProps> = (props) => {
  const productPhotoUrls: string[] = useSelector((state: AppState) => state.product.productPhotoUrls);
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false); //if this thumb deleted by close button

  const dispatch = useDispatch();

  const {photoFile, index} = props;

  useEffect(() => {
    if (photoFile.status === UPLOAD_STATUS.UPLOADED && photoFile.preview) {
      retrieveFile(photoFile.preview).then((result) => {
        setLoading(false);
        setSignedUrl(result);
        dispatch(addPhotoUrl(result));
      });
      return;
    }

    if (!photoFile.file) {
      return;
    }

    if (photoFile.status === UPLOAD_STATUS.UPLOADING) {
      uploadFile(photoFile.file);
    }

  }, []);

  useEffect(() => {

    if (!photoFile.preview || photoFile.status === UPLOAD_STATUS.UPLOADING) {
      return;
    }

    // console.log(photoFile);

    const photoUrl: string = photoFile.preview;
    if (productPhotoUrls.indexOf(photoUrl) === -1) {
      setIsDeleted(true);
    } else {
      setIsDeleted(false);
    }
  }, [productPhotoUrls]);

  // useEffect(() => {
  //   retrieveFile(photoUrl).then((result) => {
  //     setSignedUrl(result);
  //   });
  // }, []);

  const uploadFile = (async (file: File) => {
    const resizeOptions: ImageResizeOptions = {file, maxWidth: 1200, maxHeight: 900,
      compressFormat: 'JPEG', quality: 80, rotation: 0, fileName: file.name};
    const resizedFile: File = await resizeFile(resizeOptions);

    const s3Url: string = await uploadImage(resizedFile);

    setLoading(false);
    setSignedUrl(s3Url);
    dispatch(addPhotoUrl(s3Url));
    // console.log(s3Url);
  });

  const ThumbLoading = () => {
    return (
      <Spinner animation="grow" variant="info"/>
    );
  };

  const handleDeletePhoto = () => {
    const photoUrl: string | null = photoFile.status === UPLOAD_STATUS.UPLOADED && photoFile.preview ? photoFile.preview
      : signedUrl ? signedUrl.split('?')[0] : null;

    if (!photoUrl) {
      return;
    }

    dispatch(deletePhoto(photoUrl));
  };

  if (isDeleted) {
    return <React.Fragment/>
    // return <label>deleted</label>
  }

  return (
    <React.Fragment>
      <div className='thumbs'>
        {loading && ThumbLoading()}
        <div>
          {!loading && <div className='remove-passport' onClick={handleDeletePhoto}>x</div>}
          {signedUrl &&
          <Image
              src={signedUrl}
              alt='spencehub'
            // onLoad={() => setLoading(true)}
          />}

          {/*{!signedUrl && <label>unsigned</label>}*/}
        </div>
      </div>
    </React.Fragment>
  )
};

export default Thumb;