import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {addNewSpecItem} from "../../store/actions/SpecsAction";

const AddSpecItem: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <Row>
      <Col md={5} xs={8} className='my-1'/>
      <Col md={1} xs={1} className='my-1 text-right add-spec'>
        <i className='feather icon-plus' onClick={() => dispatch(addNewSpecItem())}/>
      </Col>
    </Row>
  )
};
export default AddSpecItem;