import React from "react";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FE_ROUTES} from "../constants/routes";
import logo from '../assets/img/logo.png';
import bg from '../assets/img/bg.png';
import Footer from "../layouts/Footer";
import AttributionText from "../components/AttributionText/AttributionText";
import {STORAGE_USER} from "../constants/StorageUser";

const Home: React.FC = () => {

  const StorageUser = (localStorage.getItem(STORAGE_USER));

  return (
    <React.Fragment>
      <Container fluid={true} className='wrapper-home pl-0 pr-0'>
        <Row>
          <Col xs={6} sm={6} lg={{span: 5, offset: 1}}>
            <div className='logo-home'>
              <Image src={logo} alt='SpenceHub'/>
            </div>
          </Col>
          <Col xs={6} sm={6} lg={6}>
            {StorageUser && <div className='text-right home-btn'>
                <Link to={FE_ROUTES.DASHBOARD}>
                    <Button variant='primary' className='signIn-btn'>Dashboard</Button>
                </Link>
            </div>}
            {!StorageUser && <div className='text-right home-btn'>
                <Link to={FE_ROUTES.DASHBOARD}>
                    <Button variant='primary' className='signIn-btn'>Sign in</Button>
                </Link>
            </div>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={{span: 5, offset: 1}}>
            <div className='customer-img'>
              <Image src={bg} alt='spence Hub'/>
              <AttributionText/>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={{span: 6, offset: 0}} className='bg-lines'>
            <div className='text-center home-title'>
              <h2>Get a <br/>
                predictable<br/>
                monthly<br/>
                income</h2>
            </div>
            <div className='text-center home-sub-text'>
              <p>GO ONLINE AND MAKE IT HAPPEN</p>
            </div>
            <Row className='flex-grow-0'>
              <Col xs={6} lg={6} className='text-lg-right home-btn pr-1'>
                <a
                  href='https://optin.spencehub.com/nextgen-web-store-that-generate-predictable-income-sri-lanka?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoib3B0aW4tdiIsIkREIjoiNWVkNGNhZTcyODQwMDIzMzQwMGZhMjFjIiwiU1QiOjE1OTEwMDM4Nzk2NDQsImlhdCI6MTU5MTAwMzg3OX0.ShgERKW_x4sd8wkGjyPrKAA_TNivO1tU_7XAlOMu_x8'
                  target='_blank'>
                  <Button variant='outline-light' className='pricing-btn'>GET A COUPON</Button>
                </a>
              </Col>
              <Col xs={6} lg={6} className='text-left home-btn pl-1'>
                <a
                  href='https://store.spencehub.com/nextgen-web-store-that-generate-predictable-income?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoic3RvcmUtdiIsIkREIjoiNWVkNGNlNDk5NTRlYTQzNWVlNmJiMmI1IiwiU1QiOjE1OTEwMDQ3NDU1NzMsImlhdCI6MTU5MTAwNDc0NX0.aR2SQDwv0XNrosgS_MJ6GeC9dmxVAGkQLj5n8-RoGOo'
                  target='_blank'>
                  <Button variant='outline-light' className='demo-btn'>DEMO</Button>
                </a>
              </Col>
            </Row>
            <div className='text-center mt-3 pricing-text'>
              <a href='https://store.spencehub.com/pricing' target='_blank'>
                PRICING
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </React.Fragment>
  );
};

export default Home;
