import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/react-hooks";
import { GET_INVOICES } from "../../graphql/Queries/Invoice";
import { InvoiceData } from "../../types/InvoiceTypes";
import { InvoiceListRow } from "../../types/table/TableTypes";
import NumberFormat from "react-number-format";
import { smallCentsWithPrefix } from "../../util/uiComponents";
import TableToolKit from "../../components/TableNext/TableToolKit";
import { listInvoiceColumns } from "../../constants/TableColumns";
import BtnViewInvoice from "./CartRow/BtnViewInvoice";

const ListInvoice: React.FC = () => {
  const { loading, error, data } = useQuery(GET_INVOICES);

  if (loading)
    return (
      <div>
        <p>Loading...</p>
      </div>
    );

  if (error) return <p> Error..</p>;

  const listInvoiceRows = (invoices: InvoiceData[]) => {
    return invoices.map((invoice: InvoiceData, index: number) => {
      const invoiceDate = new Date(parseInt(invoice.createdAt));
      invoices.sort(
        (a: InvoiceData, b: InvoiceData) => b.invoice_id - a.invoice_id
      );

      const payType = invoice.payType ? invoice.payType : "COD";
      const destinationName = invoice.billingInfo
        ? invoice.billingInfo.fullName
        : "-";

      const invoiceRow: InvoiceListRow = {
        key: index + 1,
        invoice_id: invoice.invoice_id,
        fullName: invoice.personalInfo.fullName,
        destinationName: destinationName,
        date: invoiceDate.toLocaleString("en-GB", { hour12: true }),
        payType: payType,
        total: (
          <NumberFormat
            value={invoice.total}
            thousandSeparator={true}
            displayType="text"
            prefix="Rs. "
            decimalScale={2}
            fixedDecimalScale={true}
            renderText={smallCentsWithPrefix}
          />
        ),
        viewIcon: <BtnViewInvoice id={invoice._id} />,
      };
      return invoiceRow;
    });
  };

  const getInvoiceTable = () => {
    // console.log(data.invoices);
    const rows = listInvoiceRows(data.invoices);
    console.log(data.invoices);
    return (
      <TableToolKit
        columns={listInvoiceColumns}
        tableData={rows}
        keyField={"key"}
        search={""}
        tableClasses="invoice-table"
        noDataTitle={"No orders found"}
      />
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12} xs={12}>
          <Card className="card-user">
            <Card.Header>
              <Card.Title>Invoices</Card.Title>
              {/*TODO: Filter invoices from Date range*/}
              {/*<DateRange/>*/}
              {/*<DateRangePicker onDateRangeChange={this.handleDateRangeChange}/>*/}
              {/*<hr/>*/}
            </Card.Header>
            <Card.Body>
              {getInvoiceTable()}
              {/*<AllInvoices fromDate={fromDate} toDate={toDate}/>*/}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ListInvoice;
