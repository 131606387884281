import React from 'react';
// import PerfectScrollbar from "perfect-scrollbar";
import {Link, NavLink} from "react-router-dom";
import logo from "../../assets/img/logo.png";
import {Nav} from "react-bootstrap";

const Sidebar: React.FC<any> = (props) => {
  const activeRoute = (routeName: string) => {
    if (routeName !== '/dashboard/'){
      return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    return routeName.indexOf(props.location.pathname) > -1 ? "active" : "";
  };

  // useEffect(() => {
  //
  //     if (navigator.platform.indexOf("Win") > -1) {
  //         ps = new PerfectScrollbar(ref.sidebar, {
  //             suppressScrollX: true,
  //             suppressScrollY: false
  //         });
  //     }
  // }, []);


  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo">
        <Link to='/dashboard'>
          <div className='spenceHub-logo'>
            <img src={logo} alt="SpenceHub"/>
          </div>
        </Link>
      </div>
      <div className="sidebar-wrapper">
        <Nav>
          {props.routes.map((prop: any, key: number) => {
            if (prop.redirect) return null;
            return (
              <li
                onClick={() => props.openSideBar()}
                className={activeRoute(prop.path)}
                key={key}
              >
                <NavLink
                  to={prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon}/>
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  )
};

export default Sidebar;