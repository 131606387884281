import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {setSpecEditorVisibility} from "../../store/actions/SpecsAction";

const AddSpecLabel: React.FC = () => {

  const dispatch = useDispatch();

  return (
    <Row>
      <Col md={12} xs={12} className="mb-0">
        <Form.Label className="add-specs-label mb-0" onClick={() => dispatch(setSpecEditorVisibility(true))}>Add Specs</Form.Label>
      </Col>
    </Row>
  )
};
export default AddSpecLabel;