import {ProductActionTypes, ProductState} from "../../types/ProductTypes";
import {
  ADD_PHOTO_URL,
  DELETE_PHOTO,
  REMOVE_PHOTO_URLS,
  SET_UPLOAD_PHOTO_URL_STATUS
} from "../../constants/actions/productActions";

const productInitialState: ProductState = {
  productPhotoUrls: [],
  isPhotoUploading: false,
};

export function productReducer(
  state = productInitialState,
  action: ProductActionTypes
): ProductState {
  switch (action.type) {
    case ADD_PHOTO_URL:
      const photoUrls = state.productPhotoUrls.slice();
      photoUrls.push(action.payload.split('?')[0]);

      return {
        ...state,
        // productPhotoUrls: state.productPhotoUrls.concat([action.payload])
        productPhotoUrls: photoUrls
      };
    case SET_UPLOAD_PHOTO_URL_STATUS: {
        return {
          ...state,
          isPhotoUploading: action.payload
        }
      }
    case REMOVE_PHOTO_URLS: {
      return {
        ...state,
        productPhotoUrls: []
      }
    }
    case DELETE_PHOTO: {
      const productPhotoUrls = state.productPhotoUrls.slice();
      const photoIndex = productPhotoUrls.indexOf(action.payload);
      productPhotoUrls.splice(photoIndex, 1);
      return {
        ...state,
        productPhotoUrls: productPhotoUrls
      }
    }

    default: {
      return state;
    }
  }
}