import React from "react";
import {Row, Col} from "react-bootstrap";
import {smallCentsWithPrefix} from "../../util/uiComponents";
import NumberFormat from "react-number-format";
import {ItemInvoice} from "../../types/InvoiceTypes";

type InvoiceSummaryProps = {
  items: ItemInvoice[] | undefined;
  shipping: number | undefined;
  total: number | undefined;
  currencyType: string;
};
const InvoiceSummary: React.FC<InvoiceSummaryProps> = (props) => {
  const {items, shipping, total, currencyType} = props;
  if (!items) return <div/>;
  if (!total) return <div/>;

  const displayTotal = () => {
    if (shipping) {
      return <NumberFormat
        value={total + shipping}
        thousandSeparator={true}
        displayType="text"
        prefix={currencyType}
        decimalScale={2}
        fixedDecimalScale={true}
        renderText={smallCentsWithPrefix}
      />
    }
    return <NumberFormat
      value={total}
      thousandSeparator={true}
      displayType="text"
      prefix={currencyType}
      decimalScale={2}
      fixedDecimalScale={true}
      renderText={smallCentsWithPrefix}
    />
  }


  return (
    <Row className="pt-2">
      <Col sm={5} xs={12} className="border-right pr-6">
        <Row>
          <Col xs={12} className="bill-summary-row ml-1">
            <p>No of items</p>
            <p>{items.length}</p>
          </Col>
          <Col xs={12} className="bill-summary-row ml-1">
            <p>Tax</p>
            <p>0.00</p>
          </Col>
          <Col xs={12} className="bill-summary-row ml-1">
            <p>Amount</p>
            <p>
              <NumberFormat
                value={total}
                thousandSeparator={true}
                displayType="text"
                prefix={currencyType}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={smallCentsWithPrefix}
              />
            </p>
          </Col>
          <Col xs={12} className="bill-summary-row ml-1">
            <p>Delivery Charge</p>
            <p>
              {shipping && (
                <NumberFormat
                  value={shipping}
                  thousandSeparator={true}
                  displayType="text"
                  prefix={currencyType}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  renderText={smallCentsWithPrefix}
                />
              )}
            </p>
          </Col>
        </Row>
      </Col>
      <Col sm={7} xs={12} className="pr-6">
        <Row>
          <Col xs={12} className="bill-summary-row total-row">
            <p>Total</p>
            <span>
              {displayTotal()}
            </span>
          </Col>

          <Col xs={12} className="bill-summary-row">
            <p className="">Cash</p>
            <span>
              <NumberFormat
                value={0}
                className="ml-2"
                thousandSeparator={true}
                displayType="text"
                prefix={currencyType}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={smallCentsWithPrefix}
              />
            </span>
          </Col>
          <Col xs={12} className="bill-summary-row">
            <p>Balance</p>
            <span>
              <NumberFormat
                value={0}
                thousandSeparator={true}
                displayType="text"
                prefix={currencyType}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={smallCentsWithPrefix}
              />
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default InvoiceSummary;
