import {combineReducers} from "redux";
import {categoryReducer} from "./CategoryReducer";
import {specsReducer} from "./SpecReducer";
import {productReducer} from "./ProductReducer";

export const rootReducer = combineReducers({
  categories : categoryReducer,
  spec: specsReducer,
  product: productReducer
});

export type AppState = ReturnType<typeof rootReducer>