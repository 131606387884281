export const listItemsColumns = [
    {
        dataField: "key",
        text: "#",
        sort: true,
        classes: 'small-column' //these classes are added to td only, not th
    },
    {
        dataField: "title",
        text: "Name",
        sort: true,
        classes: 'text-left'
    },
    {
        dataField: "product_code",
        text: "Product Code",
        sort: true,
        classes: 'text-left text-bold'
    },
    {
        dataField: "category",
        text: "Category",
        sort: true,
        classes: 'text-center'
    },
    {
        dataField: "subCat",
        text: "Sub Category",
        sort: false,
        classes: 'text-left'
    },
    {
        dataField: "status",
        text: "Status",
        sort: false,
        classes: 'text-center'
    },
    {
        dataField: "price",
        text: "Price",
        sort: true,
        classes: 'text-center'
    },
    {
        dataField: "removeIcon",
        text: "",
        sort: false,
        classes: 'small-column',
    },
    {
        dataField: "editIcon",
        text: "",
        sort: false,
        classes: 'small-column'
    },
];

export const listInvoiceColumns = [
    {
        dataField: "key",
        text: "#",
        sort: false,
        classes: 'small-column' //these classes are added to td only, not th
    },
    {
        dataField: "invoice_id",
        text: "Invoice No",
        sort: true,
        classes: 'text-left'
    },
    {
        dataField: "date",
        text: "Invoice Date",
        sort: true,
        classes: 'text-left text-bold'
    },
    {
        dataField: "fullName",
        text: "Customer Name",
        sort: true,
        classes: 'text-left text-bold'
    },
    {
        dataField: "destinationName",
        text: "Destination Name",
        sort: true,
        classes: 'text-left text-bold'
    },
    {
        dataField: "payType",
        text: "Payment Type",
        sort: true,
        classes: 'text-center'
    },
    {
        dataField: "total",
        text: "Total",
        sort: true,
        classes: 'text-right'
    },
    {
        dataField: "viewIcon",
        text: "",
        sort: false,
        classes: 'small-column',
    },
    // {
    //     dataField: "editIcon",
    //     text: "",
    //     sort: false,
    //     classes: 'small-column'
    // },
];