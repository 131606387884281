import React from "react";
import {Row, Col} from "react-bootstrap";
import InvoiceItem from "./InvoiceItem";
import {ItemInvoice} from "../../types/InvoiceTypes";

type InvoiceItemsTable = {
  items: ItemInvoice[] | undefined;
  currencyType: string;
};

const InvoiceItemsTable: React.FC<InvoiceItemsTable> = (props) => {
  const {items, currencyType} = props;

  if (!items) return <div/>;

  return (
    <React.Fragment>
      <Row className="item-list-header print-mt-40 mt-2 ml-sm-1 mr-sm-1 pt-1 pb-1">
        <Col xs={1} className="border-right">
          #
        </Col>
        <Col className="border-right col-print-6">Item name</Col>
        <Col className="border-right text-right amount-th col-print-2">
          Price ({currencyType})
        </Col>
        <Col xs={2} className="border-right text-center col-print-1">
          Qty
        </Col>
        <Col xs={3} md={2} className="text-right">
          Amount ({currencyType})
        </Col>
      </Row>
      <Row>
        <Col className="items-container mb-5">
          {items.map((item, key) => {
            return (
              <InvoiceItem key={key} qty={item.qty} product={item.product} num={key + 1}/>);
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default InvoiceItemsTable;
