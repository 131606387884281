import React, { useState } from "react";
import { InputGroup, FormControl, Button, Form } from "react-bootstrap";
import axios from "axios";
import { API_ROUTES } from "../../constants/routes";
import { Redirect, NavLink } from "react-router-dom";
import { STORAGE_USER } from "../../constants/StorageUser";
import { getLoadingSpan } from "../../util/uiComponents";

type SignIn2Props = {
  from?: any;
};

const Login: React.FC<SignIn2Props> = (props) => {
  const [loginState, setLoginState] = useState<SignInState>({
    username: null,
    password: null,
    isLoginError: false,
    message: null,
    redirect: false,
    redirectTo: "/dashboard",
    processing: false,
  });

  const [agreement, setAgreement] = useState(false);

  // const redirect: string | null = useSelector((state: AppState) => state.client.redirectTo);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, username: event.target.value });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, password: event.target.value });
  };

  const handleLogin = (data: any) => {
    // const user = { auth: true, token: "a", role: "admin" };
    const user = {
      auth: data.auth,
      token: data.token,
      role: data.role,
      name: data.name,
      AG: data.AG,
      // company: data.company
    };

    localStorage.setItem(STORAGE_USER, JSON.stringify(user));

    // if (this.props.history.length > 1) {
    //   this.props.history.goBack();
    // }

    let redirectTo = user.AG ? '/dashboard' : "/terms";

    // switch (data.role) {
    //     case USER_ROLES.SUPER_ADMIN:
    //     case USER_ROLES.STORE_ADMIN:
    //     case USER_ROLES.STORE_USER:
    //     case USER_ROLES.APP_USER:
    //         redirectTo = "/dashboard";
    //         break;
    // }

    // if (redirect) {
    //     redirectTo = redirect;
    // }
    setAgreement(data.AG);
    setLoginState({ ...loginState, redirect: true, redirectTo });

    // this.props.history.push("/");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginState({
      ...loginState,
      message: "",
      isLoginError: false,
      processing: true,
    });

    const auth = {
      username: loginState.username,
      password: loginState.password,
      login_type: "password",
    };

    axios
      .post(API_ROUTES.LOGIN, auth)
      // .post('API_ROUTES', auth)
      .then((res: any) => {
        // console.log('Success' + res);
        setLoginState({ ...loginState, processing: false });

        if (res.data.auth) {
          setLoginState({ ...loginState, isLoginError: false });
          handleLogin(res.data);
        }
      })
      .catch((err: any) => {
        // console.log('Error' + err);

        setLoginState({ ...loginState, processing: false });

        const message = err.response ? err.response.data : "Network Error";
        // localStorage.removeItem("user");

        if (!err.response) {
          setLoginState({
            ...loginState,
            message: message,
            isLoginError: true,
          });
        }

        if (err.response && err.response.status === 403) {
          setLoginState({
            ...loginState,
            message: message,
            isLoginError: true,
          });
        }


        if (err.response && err.response.status === 400) {
          setLoginState({
            ...loginState,
            message: "Problem occurred. Try again!",
            isLoginError: true,
          });
        }
      });
  };

  const renderRedirect = () => {
    if (loginState.redirect && !agreement) {
      return <Redirect to={loginState.redirectTo} />;
      //   props.history.push(loginState.redirectTo);s
    } else if (loginState.redirect && agreement) {
      return <Redirect to="/dashboard" />;
    }
  };

  return (
    <React.Fragment>
      {renderRedirect()}
      <div className="login-dialog">
        <h3>Login</h3>

        <Form className="login-form" onSubmit={handleSubmit}>
          <InputGroup>
            <InputGroup.Prepend>
              <i className="nc-icon nc-email-85" />
            </InputGroup.Prepend>
            <FormControl
              type="email"
              name="email"
              placeholder="E-mail"
              required
              onChange={handleUsernameChange}
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Prepend>
              <i className="nc-icon nc-lock-circle-open" />
            </InputGroup.Prepend>
            <FormControl
              className="form-style"
              type="password"
              name="password"
              placeholder="Password"
              required
              onChange={handlePasswordChange}
            />
          </InputGroup>

          <div className="form-group save-text text-left">
            <Form.Check
              type="checkbox"
              id="customControlAutosizing"
              label={<p>Save credentials</p>}
              custom
              defaultChecked={false}
            />
          </div>

          <div className="error-container">
            {loginState.isLoginError && (
              <p className="login-error">{loginState.message}</p>
            )}
          </div>

          <div className="login ml-auto mr-auto">
            <Button
              variant="primary"
              className="btn-block"
              type="submit"
              disabled={loginState.processing}
            >
              {loginState.processing && getLoadingSpan()}
              Login
            </Button>
          </div>
          <hr className="divider" />
          <div>
            <p className="developer">
              {" "}
              &copy; {new Date().getFullYear()} developed by{" "}
              <a href="http://www.softvessel.com" target="_blank">
                {" "}
                SoftVessel
              </a>
            </p>
          </div>
        </Form>
        <p className="mb-2 text-muted">
          Forgot password? <NavLink to="/auth/reset-password-2">Reset</NavLink>
        </p>
        <p className="mb-0 text-muted">
          Don’t have an account? <NavLink to="/auth/register">Signup</NavLink>
        </p>
      </div>
    </React.Fragment>
  );
};

interface SignInState {
  username: string | null;
  password: string | null;
  isLoginError: boolean;
  message: string | null;
  redirect: boolean;
  redirectTo: string;
  processing: boolean;
}

export default Login;
