import React, {ChangeEvent, useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_PRODUCT, GET_PRODUCTS} from "../../graphql/Queries/Product";
import {UPDATE_PRODUCT} from "../../graphql/Mutations/Product";
import {useParams} from "react-router-dom";
import ImagesUpload from "../../components/ImagesUpload/ImagesUpload";
import {IPhoto, IProduct} from "../../types/Types";
import ProductCategory from "./ProductCategory/ProductCategory";
import {CategoryData, SubCategory} from "../../types/CategoryTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {setSelectedCategory, setSelectedSubCategory} from "../../store/actions/CategoryActions";
import {retrieveFile} from "../../components/UploadToBucket/UploadToBucket";
import {resetSpec, setSpec} from "../../store/actions/SpecsAction";
import Spec from "../../components/Product/Spec";
import {Spec as SpecType, SpecItem} from "../../types/SpecStoreTypes";
import {removePhotoUrls} from "../../store/actions/ProductActions";

const UpdateProduct: React.FC = () => {
  const {id} = useParams();
  // console.log(id);

  const [product, setProduct] = useState<IProduct | null>();
  const {loading, error, data} = useQuery(GET_PRODUCT, {
    variables: {id: id}
  });

  const [editProduct, {data: editData}] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: () => [{query: GET_PRODUCTS}]
  });

  const dispatch = useDispatch();

  const categories: CategoryData[] = useSelector((state: AppState) =>
    state.categories.categories);

  const selectedCategory: CategoryData | null = useSelector((state: AppState) =>
    state.categories.selectedCategory);
  const selectedSubCategory: SubCategory | null = useSelector((state: AppState) =>
    state.categories.subCategory);
  const spec: SpecType| null = useSelector((state: AppState) => state.spec.spec);
  const productPhotoUrls: string[] = useSelector((state: AppState) => state.product.productPhotoUrls);

  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [main_Category, setMainCategory] = useState('');
  const [sub_Category, setSubCategory] = useState('');
  const [featureCategory, setFeatureCategory] = useState('');
  const [price, setPrice] = useState(0);
  const [discountType, setDiscountType] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [show, setShow] = useState(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [signedUrl, setSignedUrl] = useState<string | null>(null);

  const setMainCategoryToStore = () => {
    const mainCategoryData: CategoryData | undefined = categories.find((category: CategoryData) =>
      main_Category === category._id);

    if (!mainCategoryData) {
      return;
    }

    dispatch(setSelectedCategory(mainCategoryData));

    const subCategoryData: SubCategory | undefined = mainCategoryData.subCategories.find(
      (subCategory: SubCategory) => subCategory._id === sub_Category);

    if (!subCategoryData) {
      return;
    }

    dispatch(setSelectedSubCategory(subCategoryData));
  };

  useEffect(() => {
    if (categories.length === 0 || selectedCategory) {
      return;
    }
    setMainCategoryToStore();
  }, [categories]);

  useEffect(() => {
    if (main_Category === '') {
      return;
    }

    setMainCategoryToStore();
  }, [main_Category]);

  useEffect(() => {
    dispatch(removePhotoUrls());

    return () => {
      // console.log('Update destroy')
      dispatch(setSelectedCategory(null));
      dispatch(setSelectedSubCategory(null));
      dispatch(resetSpec());
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.product.specs.length > 0) {
        dispatch(setSpec(data.product.specs[0]));
      }
      setProduct(data.product);
      setProductName(data.product.title);
      setProductCode(data.product.product_code);
      setMainCategory(data.product.category.main_category._id);
      setSubCategory(data.product.category.sub_category._id);
      setFeatureCategory(data.product.category.feature_category);
      setPrice(data.product.price.amount);
      setDiscountType(data.product.price.discount.discount_type);
      setDiscountAmount(data.product.price.discount.discount_amount);
      setDescription(data.product.description.long_details);
      const urls = data.product.photos.map((photo: IPhoto) => photo.main_photo);
      setImageUrls(urls);
    }
  }, [data]);

  const handleChangeProductName = (e: ChangeEvent<HTMLInputElement>) => {
    setProductName(e.target.value)
  };

  const handleChangeProductCode = (e: ChangeEvent<HTMLInputElement>) => {
    setProductCode(e.target.value)
  };

  const handleChangeFeatureCategory = (e: ChangeEvent<HTMLInputElement>) => {
    setFeatureCategory(e.target.value)
  };

  const handleChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
    setPrice(parseFloat(e.target.value))
  };

  const handleChangeDiscountType = (e: ChangeEvent<HTMLInputElement>) => {
    setDiscountType(e.target.value)
  };

  const handleChangeDiscountAmount = (e: ChangeEvent<HTMLInputElement>) => {
    setDiscountAmount(parseFloat(e.target.value))
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();

    let specs: SpecType[] = [];
    if(!spec){
      specs = [];
    }
    if(spec){
      const specTitle = spec.title;
      const itemsWithoutTypeName: SpecItem[] = [];

      spec.specItems.map((item) => {
        const label = item.label;
        const description = item.description;
        itemsWithoutTypeName.push({description: description, label:label});
        return itemsWithoutTypeName;
      });

      const specWithoutTypeName: SpecType = {specItems: itemsWithoutTypeName, title:specTitle};
      specs.push(specWithoutTypeName);
    }

    const photos: IPhoto[] = productPhotoUrls.map((url: string) => {
      const photo: IPhoto = {main_photo: url};
      return photo;
    });

    const mainCategory: string | null = selectedCategory ? selectedCategory._id : null;
    const subCategory: string | null = selectedSubCategory ? selectedSubCategory._id : null;


    editProduct({
      variables: {
        _id: id,
        business: product ? product.business : '',
        title: productName,
        product_code: productCode,
        category: {main_category: mainCategory, sub_category: subCategory, feature_category: featureCategory},
        price: {amount: price, discount: {discount_type: discountType, discount_amount: discountAmount}},
        description: {long_details: description},
        photos: photos,
        specs: specs
      }
    });


  };

  if (error) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          Please try again
        </p>
      </Alert>
    );
  }

  if (loading) return <div>
    <p>Loading...</p>
  </div>;

  if (error) return <p> Error..</p>;

  // const getImages = (products: IProduct[]) => {
  //     return (
  //         products.map((product: IProduct, index: number) => {
  //             return <Image key={index} src={product.photos.main_photo}/>
  //         })
  //     )
  // };

  return (
    <div>
      <Row>
        <Col md={12} xs={12}>
          <Card className="card-user">
            <Card.Header>
              <Card.Title>Update Product</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={4} xs={12} className='ver-divider pr-4'>
                  <Card.Header>
                    <Card.Title>Upload Product Images</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <ImagesUpload uploadedPhotos={imageUrls}/>
                  </Card.Body>
                </Col>
                <Col md={8} xs={12}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Row className="align-items-center">
                      <Col md={6} lg={6} className="my-1">
                        <Form.Label>Title / Product Name</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="Product Name"
                                      value={productName}
                                      onChange={handleChangeProductName}/>
                      </Col>
                      <Col md={6} lg={6} className="my-1">
                        <Form.Label>Product Code</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="Eg: FDD001"
                                      onChange={handleChangeProductCode}
                                      value={productCode}
                        />
                      </Col>
                    </Form.Row>

                    {/*Product Category component*/}
                    <ProductCategory/>

                    <Form.Row className="align-items-center">
                      <Col md={4} lg={4} className='my-1'>
                        <Form.Label>Price</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="price"
                                      onChange={handleChangePrice}
                                      value={price}
                        />
                      </Col>
                      <Col md={4} lg={4} className='my-1'>
                        <Form.Label>Discount Type</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="% or amount"
                                      onChange={handleChangeDiscountType}
                                      value={discountType}
                        />
                      </Col>
                      <Col md={4} lg={4} className='my-1'>
                        <Form.Label>Discount Amount</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="20% or 500"
                                      onChange={handleChangeDiscountAmount}
                                      value={discountAmount}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" placeholder="Here can be your description"
                                    onChange={handleChangeDescription}
                                    value={description}
                      />
                    </Form.Group>

                    <Spec/>

                    <Row>
                      <Col md={12} xs={12}>
                        {(editData && editData.editProduct._id) &&
                        <Alert variant={"success"}>
                            Product successfully Updated!
                        </Alert>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="update ml-auto mr-auto add-btn text-right">
                        <Button variant='primary' className='submit-btn' type='submit'>Update
                          Product</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default UpdateProduct;