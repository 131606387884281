import gql from "graphql-tag";

const GET_CATEGORIES = gql`
  query allCategories {
    allCategories {
      _id
      mainCategory
      photo
      subCategories {
        _id
        subCategory
      }
    }
  }
`;

export {  GET_CATEGORIES };
