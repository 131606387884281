import React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { EDIT_SUB_CATEGORY } from "../../graphql/Mutations/Category";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";
import { CategoryData, SubCategory } from "../../types/CategoryTypes";
import { AppState } from "../../store/reducers";
import { setSelectedCategory } from "../../store/actions/CategoryActions";

interface OptionsProps {
  toggleCategoryInputs: any;
  subCategoryId: string;
}

const OptionsSubCategory: React.FC<OptionsProps> = (props) => {
  const selectedCategory: CategoryData | null = useSelector(
    (state: AppState) => state.categories.selectedCategory
  );
  const dispatch = useDispatch();

  const [deleteSubCategory, { data: deleteData }] = useMutation(
    EDIT_SUB_CATEGORY,

    {
      refetchQueries: ({ data: { addCategory } }) => [
        { query: GET_CATEGORIES },
      ],
    }
  );

  const handleRemoveClick = () => {
    if (!selectedCategory) {
      return;
    }

    const subCategories:
      | SubCategory[]
      | null = selectedCategory.subCategories.filter(
      (item: SubCategory) => item._id !== props.subCategoryId
    );
    console.log(subCategories);

    if (subCategories === null) {
      return;
    }
    const typeRemovedSubCategories = subCategories.map((item: any) => {
      if (item.__typename) {
        delete item.__typename;
        return item;
      }
      return item;
    });

    deleteSubCategory({
      variables: {
        _id: selectedCategory._id,
        subCategories: typeRemovedSubCategories,
      },
    });

    dispatch(
      setSelectedCategory({ ...selectedCategory, subCategories: subCategories })
    );
  };

  return (
    <div className="options-main-wrapper">
      <div className="editor-options table-options">
        <Dropdown alignRight={true} className="btn-group ">
          <Dropdown.Toggle id="dropdown--table-options" className="btn-icon">
            <i className="feather icon-more-horizontal" />
          </Dropdown.Toggle>
          <Dropdown.Menu as="ul" className="list-unstyled card-option">
            <Dropdown.Item
              as="li"
              className="dropdown-item"
              onClick={() => props.toggleCategoryInputs()}
            >
              <i className="feather icon-edit" />
              <span> Edit </span>
            </Dropdown.Item>
            <Dropdown.Item
              as="li"
              className="dropdown-item remove"
              onClick={handleRemoveClick}
            >
              <i className={`feather icon-x`} />
              <span> Remove </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default OptionsSubCategory;
