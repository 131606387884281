import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_CATEGORY } from "../../graphql/Mutations/Category";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";
import { useDispatch } from "react-redux";
import { setSelectedCategory } from "../../store/actions/CategoryActions";
import DeleteCategoryModel from "./DeleteCategoryModel";

interface OptionsProps {
  toggleCategoryInputs: any;
  deleteCategoryId: string;
}

const Options: React.FC<OptionsProps> = (props) => {
  const [deleteCategory, { data: deleteData }] = useMutation(DELETE_CATEGORY, {
    refetchQueries: ({ data: { addCategory } }) => [{ query: GET_CATEGORIES }],
  });

  const [toggleModel, setToggleModel] = useState(false);

  const dispatch = useDispatch();
  const handleRemoveClick = () => {
    deleteCategory({
      variables: {
        id: props.deleteCategoryId,
      },
    });
    dispatch(setSelectedCategory(null));
  };

  const toggleModelFunction = () => {
    setToggleModel(!toggleModel);
    // console.log("-------------");
  };

  return (
    <div className="options-main-wrapper">
      <div className="editor-options table-options">
        <Dropdown alignRight={true} className="btn-group ">
          <Dropdown.Toggle id="dropdown--table-options" className="btn-icon">
            <i className="feather icon-more-horizontal" />
          </Dropdown.Toggle>
          <Dropdown.Menu as="ul" className="list-unstyled card-option">
            <Dropdown.Item
              as="li"
              className="dropdown-item"
              onClick={() => props.toggleCategoryInputs()}
            >
              <i className="feather icon-edit" />
              <span> Edit </span>
            </Dropdown.Item>
            <Dropdown.Item
              as="li"
              className="dropdown-item remove"
              onClick={toggleModelFunction}
            >
              <DeleteCategoryModel
                deleteFunction={handleRemoveClick}
                status={toggleModel}
              />
              <i className={`feather icon-x`} />
              <span onClick={() => toggleModelFunction()}> Remove </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default Options;
