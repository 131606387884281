import React, {Component} from 'react';

class AttributionText extends Component {
  render() {
    return (
      <div className='attribution-text'>
        <a href='https://www.freepik.com/vectors/business' target='_blank'>Credits: freepik</a>
      </div>
    )
  }
}

export default AttributionText;