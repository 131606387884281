import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {Link, Switch, Route} from 'react-router-dom';
import ListInvoice from "./ListInvoice";
import {ROOT_PATH} from "../../routes/dashboard";

const Invoice: React.FC = () => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} sm={6} md={6} lg={4}>
          <Link to={ROOT_PATH + '/invoice/list'} className="page-nav">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="nc-icon nc-briefcase-24 text-warning"/>
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Invoice</p>
                      <Card.Title>invoices</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr/>
                {/*<Stats>*/}
                {/*    {[*/}
                {/*        {*/}
                {/*            i: "fas fa-align-justify",*/}
                {/*            t: "Lis All Invoices"*/}
                {/*        }*/}
                {/*    ]}*/}
                {/*</Stats>*/}
              </Card.Footer>
            </Card>
          </Link>
        </Col>
      </Row>
      <Switch>
        <Route path={ROOT_PATH + '/invoice/list'} component={ListInvoice}/>
      </Switch>
    </React.Fragment>
  )
};

export default Invoice;