import gql from "graphql-tag";

const ADD_CATEGORY = gql`
  mutation addCategory(
    $mainCategory: String
    $photo: String
    $subCategories: [SubCategoryInput]
  ) {
    addCategory(
      mainCategory: $mainCategory
      photo: $photo
      subCategories: $subCategories
    ) {
      _id
      mainCategory
      photo
      subCategories {
        subCategory
      }
    }
  }
`;

const EDIT_CATEGORY = gql`
  mutation editCategory(
    $_id: String!
    $mainCategory: String
    $editPhoto: String
    $subCategories: [SubCategoryInput]
  ) {
    editCategory(
      _id: $_id
      mainCategory: $mainCategory
      photo: $editPhoto
      subCategories: $subCategories
    ) {
      _id
      mainCategory
      photo
      subCategories {
        subCategory
      }
    }
  }
`;

const EDIT_SUB_CATEGORY = gql`
  mutation editSubCategory($_id: String!, $subCategories: [SubCategoryInput]) {
    editSubCategory(_id: $_id, subCategories: $subCategories) {
      _id
      mainCategory
      photo
      subCategories {
        _id
        subCategory
      }
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(_id: $id) {
      _id
      mainCategory
      photo
      subCategories {
        subCategory
      }
    }
  }
`;

export { DELETE_CATEGORY, EDIT_SUB_CATEGORY, EDIT_CATEGORY, ADD_CATEGORY };
