import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Card, Col, Row, Form, Alert} from "react-bootstrap";
import ImagesUpload from "../../components/ImagesUpload/ImagesUpload";
import {useMutation} from "@apollo/react-hooks";
import {CREATE_PRODUCT} from '../../graphql/Mutations/Product';
import {BUSINESS_ID} from "../../variables/business";
import {IPhoto} from "../../types/Types";
import {GET_PRODUCTS} from "../../graphql/Queries/Product";
import ProductCategory from "./ProductCategory/ProductCategory";
import {CategoryData, SubCategory} from "../../types/CategoryTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {setSelectedCategory, setSelectedSubCategory} from "../../store/actions/CategoryActions";
import {Link} from "react-router-dom";
import Spec from "../../components/Product/Spec";
import {Spec as SpecType} from "../../types/SpecStoreTypes";
import {resetSpec} from "../../store/actions/SpecsAction";

export type ReactSelectOutput = {
  label: string,
  value: string
}

export type OptionsType = {
  value: string,
  label: string
}

const CreateProduct: React.FC = () => {
  const [createProduct, {data: addData, loading, error, called}] = useMutation(CREATE_PRODUCT, {
    refetchQueries: () => [{query: GET_PRODUCTS}],
    onCompleted: data => {
      if (data && data.addProduct._id) {
        setIsSuccess(true)
      }
      dispatch(setSelectedCategory(null));
      dispatch(setSelectedSubCategory(null));
    },
    onError: error1 => {
      console.log('problem subcategories')
    }
  });

  const spec: SpecType| null = useSelector((state: AppState) => state.spec.spec);
  const selectedCategory: CategoryData | null = useSelector((state: AppState) =>
    state.categories.selectedCategory);

  const selectedSubCategory: SubCategory | null = useSelector((state: AppState) =>
    state.categories.subCategory);
  const productPhotoUrls: string[] = useSelector((state: AppState) => state.product.productPhotoUrls);
  const isUploading: boolean = useSelector((state: AppState) => state.product.isPhotoUploading);

  const dispatch = useDispatch();

  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [featureCategory, setFeatureCategory] = useState('');
  const [price, setPrice] = useState<number>(0);
  const [discountType, setDiscountType] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [description, setDescription] = useState('');
  const [show, setShow] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    if (productPhotoUrls.length > 0) {
      setIsNewProduct(false);
    }
  }, [productPhotoUrls]);

  const handleChangeProductName = (e: ChangeEvent<HTMLInputElement>) => {
    setProductName(e.target.value)
  };

  const handleChangeProductCode = (e: ChangeEvent<HTMLInputElement>) => {
    setProductCode(e.target.value.toUpperCase())
  };

  // const handleChangeFeatureCategory = (e: ChangeEvent<HTMLInputElement>) => {
  //     setFeatureCategory(e.target.value)
  // };

  const handleChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
    setPrice(parseFloat(e.target.value))
  };

  const handleChangeDiscountType = (e: ChangeEvent<HTMLInputElement>) => {
    setDiscountType(e.target.value)
  };

  const handleChangeDiscountAmount = (e: ChangeEvent<HTMLInputElement>) => {
    setDiscountAmount(parseFloat(e.target.value))
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  };

  useEffect(() => {
    dispatch(setSelectedCategory(null));
    dispatch(setSelectedSubCategory(null));
    dispatch(resetSpec());
  }, []);

  const handleResetForms = () => {
    setProductName('');
    setProductCode('');
    setPrice(0);
    setDiscountType('');
    setDiscountAmount(0);
    setDescription('');
    setImageUrls([]);
    setIsValidated(false);
    setIsSuccess(false);
    setIsNewProduct(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsValidated(true);
    let specs: SpecType[] = [];
    if(!spec){
      specs = [];
    }
    if(spec){
      specs.push(spec);
    }

    if (productName === '' || productCode === '') {
      // setIsValidated(true);
      return;
    }

    const photos: IPhoto[] = productPhotoUrls.map((url: string) => {
      const photo: IPhoto = {main_photo: url};
      return photo;
    });

    const mainCategory: string | null = selectedCategory ? selectedCategory._id : null;
    const subCategory: string | null = selectedSubCategory ? selectedSubCategory._id : null;

    createProduct({
      variables: {
        business: BUSINESS_ID,
        title: productName,
        product_code: productCode,
        category: {main_category: mainCategory, sub_category: subCategory, feature_category: featureCategory},
        price: {amount: price, discount: {discount_type: discountType, discount_amount: discountAmount}},
        description: {long_details: description},
        photos: photos,
        specs: specs
      }
    });

  };

  if (error) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          Please try again
        </p>
      </Alert>
    );
  }

  return (
    <div>
      <Row>
        <Col md={12} xs={12}>
          <Card className="card-user">
            <Card.Body>
              <Row>
                <Col md={4} xs={12} className='ver-divider pr-4'>
                  <Card.Header>
                    <Card.Title>Upload Product Images</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {/*<DropzoneUpload/>*/}
                    <ImagesUpload isNewProduct={isNewProduct} uploadedPhotos={imageUrls}/>
                  </Card.Body>
                </Col>
                <Col md={8} xs={12}>
                  <Card.Header>
                    <Row>
                      <Col md={8}>
                        <Card.Title>Create Product</Card.Title>
                      </Col>
                      <Col md={4}>
                        <div className='float-right'>
                          <Button variant='outline-info' onClick={handleResetForms}>add new</Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Form onSubmit={handleSubmit} validated={isValidated} noValidate>
                    <Form.Row className="align-items-center">
                      <Col md={6} lg={6} className="my-1">
                        <Form.Label>Title / Product Name</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="Product Name"
                                      required
                                      value={productName}
                                      onChange={handleChangeProductName}/>
                      </Col>
                      <Col md={6} lg={6} className="my-1">
                        <Form.Label>Product Code</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="Eg: FDD001"
                                      required
                                      value={productCode}
                                      onChange={handleChangeProductCode}/>
                      </Col>
                    </Form.Row>

                    {/*Product Category component*/}
                    <ProductCategory/>

                    <Form.Row className="align-items-center">
                      <Col md={4} lg={4} className='my-1'>
                        <Form.Label>Price</Form.Label>
                        <Form.Control id="inlineFormInputName" type='number' placeholder="price"
                                      required
                                      min={0}
                                      value={price}
                                      onChange={handleChangePrice}/>
                      </Col>
                      <Col md={4} lg={4} className='my-1'>
                        <Form.Label>Discount Type</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="% or amount"
                                      value={discountType}
                                      onChange={handleChangeDiscountType}/>
                      </Col>
                      <Col md={4} lg={4} className='my-1'>
                        <Form.Label>Discount Amount</Form.Label>
                        <Form.Control id="inlineFormInputName" placeholder="20% or 500"
                                      value={discountAmount}
                                      onChange={handleChangeDiscountAmount}/>
                      </Col>
                    </Form.Row>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" placeholder="Here can be your description"
                                    value={description}
                                    onChange={handleChangeDescription}/>
                    </Form.Group>

                    <Spec/>

                    <Row>
                      <Col md={12} xs={12}>
                        {isSuccess &&
                        <Alert variant={"success"}>
                            Product successfully added to your inventory!
                        </Alert>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="update ml-auto mr-auto add-btn text-right">
                        <Button variant='primary' className='submit-btn' type='submit'
                                disabled={isUploading || loading || (called && isSuccess)}>Create Product</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default CreateProduct;