import gql from "graphql-tag";

const GET_SAMPLE_SPECS = gql`
    query getSampleSpecs {
        getSampleSpecs{
            categoryTitle
            labels
        }
    }
`;

export {  GET_SAMPLE_SPECS };

