import React from 'react';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import paginationFactory from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import TableCustomSearch from "./TableCustomSearch";
import {paginationOptions} from "./paginationOptions";
// import {paginationOptions} from "./paginationOptions";

type TableToolKitProps = {
  columns: any[]
  tableData: any[]
  keyField: string
  search: string
  tableClasses: string
  emptyImg?: string
  noDataTitle?: string
  noDataSubTitle?: string
}

const TableToolKit: React.FC<TableToolKitProps> = (props) => {
  const {tableData, columns, keyField, search, tableClasses} = props;

  // console.log(search);

  function indication() {
    return(
        <div className="empty-cart">
          <img src={props.emptyImg} alt={props.emptyImg}/>
          <p>{props.noDataTitle}</p>
          <label>{props.noDataSubTitle}</label>
        </div>
    )
  }

  return (
    <ToolkitProvider
      keyField={keyField}
      data={tableData}
      columns={columns}
      search={{
        defaultSearch: search
      }}
    >
      {(props: any) => (
        <div>
          <TableCustomSearch {...props.searchProps} query={search}/>
          <BootstrapTable classes={`custom-table ${tableClasses}`}
                          {...props.baseProps}
                          pagination={paginationFactory(paginationOptions)}
                          bootstrap4
                          wrapperClasses="table-responsive"
                          noDataIndication={indication}
          />
        </div>
      )}
    </ToolkitProvider>
  )
};

export default TableToolKit;