import {applyMiddleware, compose, createStore} from "redux";
import {rootReducer} from "./reducers";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

/**
 * remove composeWithDevTools option before production
 * @param preloadedState
 */
export default function configureStore(preloadedState?: any) {
  // return createStore(rootReducer, preloadedState, compose(applyMiddleware(thunkMiddleware)));
  return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
}