import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadImage } from "../../components/UploadToBucket/UploadToBucket";
import { Button, Col, Row, Image } from "react-bootstrap";

type ImagesUploadProps = {
  onImageUpload: (urls: string[]) => void;
  uploadedPhotos?: string[];
  uploadImageStatus: () => void;
};

const ImageUploadCategory: React.FC<ImagesUploadProps> = (props) => {
  const [files, setFiles] = useState([] as any);

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map(async (file) => {
        const s3Url: string = await uploadImage(file);

        const allImages = imageUrls.slice();
        allImages.push(s3Url.split("?")[0]);
        setImageUrls(allImages);

        props.onImageUpload(allImages);

        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setFiles(files.concat(previewFiles));
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <Row>
        <Col className="preview">
          {files[0] ? <Image src={files[0].preview} /> : <i className="feather icon-image"
                                                            style={{ fontSize: "120px" }} />}
        </Col>
      </Row>
      <div {...getRootProps({ className: "" })}>
        <input {...getInputProps()} />

        <Button
          variant="primary"
          className="main-category-upload-button mt-5 pr-4"
        >
          <i className="feather icon-upload p-3" />
          Upload
        </Button>
      </div>
    </section>
  );
};
export default ImageUploadCategory;
