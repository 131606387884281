import React, { useEffect, useState } from "react";
import MainCategorySelect from "./MainCategorySelect";
import MainCategoryPreview from "./MainCategoryPreview";
import { useQuery } from "@apollo/react-hooks";
import { GET_CATEGORIES } from "../../graphql/Queries/Category";
import { CategoryData, SelectCategory } from "../../types/CategoryTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategories,
  setSelectedCategory as setSelectedCategoryStore,
} from "../../store/actions/CategoryActions";
import { AppState } from "../../store/reducers";

export interface MainCategoryProps {}

const MainCategory: React.FC<MainCategoryProps> = (props) => {
  const [options, setOptions] = useState<SelectCategory[] | null>([]);
  const [allCategories, setAllCategories] = useState<CategoryData[]>([]);

  const { error, data } = useQuery(GET_CATEGORIES);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) {
      return;
    }

    const categoryOptions: SelectCategory[] | null = data.allCategories.map(
      (category: CategoryData) => {
        return {
          value: category.mainCategory,
          label: category.mainCategory,
          id: category._id,
        };
      }
    );
    if (categoryOptions === null) {
      return;
    }
    setOptions(categoryOptions);
    setAllCategories(data.allCategories);

    dispatch(setCategories(data.allCategories));
  }, [data]);

  const selectedCategory: CategoryData | null = useSelector(
    (state: AppState) => state.categories.selectedCategory
  );

  const handleOnCategorySelect = (id: string | null) => {
    const selectedCategoryIndex = allCategories.findIndex(
      (category) => category._id === id
    );
    dispatch(setSelectedCategoryStore(allCategories[selectedCategoryIndex]));
  };

  if (error) return <React.Fragment>`Error! ${error.message}`</React.Fragment>;

  if (data && options) {
    return (
      <React.Fragment>
        <MainCategorySelect
          options={options}
          onCategoryChange={handleOnCategorySelect}
        />
        <hr className="main-category-hr mt-5 mb-5" />
        {selectedCategory && (
          <MainCategoryPreview selectedCategory={selectedCategory} />
        )}
      </React.Fragment>
    );
  }

  return <React.Fragment>Loading..</React.Fragment>;
};

export default MainCategory;
