import {Spec, SpecActionTypes, SpecItem, SpecState} from "../../types/SpecStoreTypes";
import {
  ADD_SPEC_ITEM,
  REMOVE_SPEC_ITEM,
  RESET_SPEC,
  SET_SAMPLE_SPECS,
  SET_SELECTED_SAMPLE_SPEC,
  SET_SPEC,
  SET_SPEC_EDITOR_VISIBILITY,
  SET_SPEC_TITLE,
  UPDATE_SPEC_ITEM
} from "../../constants/actions/SpecActions";

const specsInitialState: SpecState = {
  isSpecEditorVisible: false,
  sampleSpecs: [],
  selectedSampleSpec: null,
  spec: null
};

export function specsReducer(
  state = specsInitialState,
  action: SpecActionTypes
): SpecState {
  switch (action.type) {

    case SET_SPEC_EDITOR_VISIBILITY:
      const newSpec: Spec = {title: "", specItems: [{label: "", description: ""}]};
      return {
        ...state,
        spec: newSpec,
        isSpecEditorVisible: action.payload
      };

    case ADD_SPEC_ITEM: {
      if (!state.spec) {
        return state;
      }
      const specItems: SpecItem[] = state.spec.specItems.slice();
      const newSpecItem: SpecItem = {label: "", description: ""};
      specItems.push(newSpecItem);
      const specWithNewItem: Spec = {...state.spec, specItems: specItems};
      return {
        ...state,
        spec: specWithNewItem
      };
    }

    case SET_SPEC_TITLE:
      if (!state.spec) {
        return state;
      }
      const specWithTitle: Spec = {...state.spec, title: action.payload};
      return {
        ...state,
        spec: specWithTitle
      };

    case UPDATE_SPEC_ITEM:
      if (!state.spec) {
        return state;
      }
      const specItems: SpecItem[] = state.spec.specItems.slice();
      specItems.splice(action.payload.index, 1, action.payload.specItem);
      const specWithUpdatedItem: Spec = {...state.spec, specItems: specItems};
      return {
        ...state,
        spec: specWithUpdatedItem
      };

    case REMOVE_SPEC_ITEM:
      if (!state.spec) {
        return state;
      }
      const specItemsRemove: SpecItem[] = state.spec.specItems.slice();
      specItemsRemove.splice(action.payload, 1);
      const specWithRemovedItem: Spec = {...state.spec, specItems: specItemsRemove};
      return {
        ...state,
        spec: specWithRemovedItem
      };

    case SET_SAMPLE_SPECS:
      return {
        ...state,
        sampleSpecs: action.payload
      };

    case SET_SELECTED_SAMPLE_SPEC:
      return {
        ...state,
        selectedSampleSpec: action.payload
      };

    case SET_SPEC:
      return {
        ...state,
        spec: action.payload,
        isSpecEditorVisible: true,
      };

    case RESET_SPEC:
      return {
        isSpecEditorVisible: false,
        sampleSpecs: [],
        selectedSampleSpec: null,
        spec: null
      };

    default: {
      return state;
    }
  }

}